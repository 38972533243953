let config = {
  currency: 'USD',
  currency_symbol: '$'
};

export const loadConfig = async () => {
  try {
    const response = await fetch('/retailers');

    if (response.ok) {
      config = await response.json();
      config = config?.retailer?.regional_config || {}
    };

  } catch (error) {
    console.error('Failed to load config:', error);
  }
};

export const getRegionalConfig = () => {
  return config;
};
