import {
  getProduct as getProductService
} from '../services';

import { GET_PRODUCT } from './types';

export const getProduct = (web_id) => (dispatch) => {
  if (web_id) {
    getProductService(web_id)
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: GET_PRODUCT, payload: data.product });
      });
  } else {
    dispatch({ type: GET_PRODUCT, payload: {} });
  }
};
