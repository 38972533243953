import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { find, forEach, isEmpty } from 'lodash';
import UserIcon from '../../../../assets/images/icono-usuario-outline.svg';
import EmailIcon from '../../../../assets/images/Email-outline.svg';
import { countriesPhonesOptions, filterCountries } from '../../../utils/countries';
import PhoneIcon from '../../../../assets/images/Phone-outline.svg';
import IdIcon from '../../../../assets/images/Id-card.svg';
import { changeUserField } from '../../../actions';
import printFilter from '../../../services/styles';
import BottomSheetModalOptions from '../BottonSheetModalOptions';

const InformationFields = ({ errors, isSmallDevice, documentTypesOptions }) => {
  const {
    name,
    lastname,
    email,
    dialCode,
    phone,
    documentType,
    documentId,
    order = {},
    orderCustomFields = []
  } = useSelector((reduxState) => reduxState.cartReducer);
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const [filterColor, setFilterColor] = useState('invert(46%) sepia(73%) saturate(472%) hue-rotate(90deg) brightness(88%) contrast(88%)');

  const dispatch = useDispatch();

  const hasOrderParam = !!(new URLSearchParams(window.location.search)).get('order');

  useEffect(() => {
    if (retailer) {
      setFilterColor(printFilter(retailer.shop_main_color));

      if (retailer.country && isEmpty(dialCode)) {
        const countryIn = find(countriesPhonesOptions, { value: retailer.country });
        if (countryIn) {
          dispatch(changeUserField('dialCode', countryIn));
        }
      }
    }
  }, [retailer]);

  const onChangeText = ({ target }) => {
    const { name: field, value } = target;
    dispatch(changeUserField(field, value));
  };

  const handleDialCodeChange = (selectedOption) => {
    dispatch(changeUserField('dialCode', selectedOption));
  };

  const handleDocumentTypeChange = (selectedOption) => {
    dispatch(changeUserField('documentType', selectedOption));
  };

  return (
    <div className="mb-3 mt-3 mt-md-0">
      <div className="row">
        <div className="col-md-6 pe-md-2 mb-3">
          <div className="checkout-input-container">
            <img src={UserIcon} alt="" style={{ filter: filterColor }} />
            <input
              onChange={onChangeText}
              type="text"
              placeholder="Nombres"
              value={name}
              name="name"
              readOnly={hasOrderParam && order.has_payment_transaction}
            />
          </div>
          <span className="ml-1 text-danger">{errors.first_name}</span>
        </div>
        <div className="col-md-6 ps-md-2 mb-3">
          <div className="checkout-input-container">
            <img src={UserIcon} alt="" style={{ filter: filterColor }} />
            <input
              onChange={onChangeText}
              type="text"
              placeholder="Apellidos"
              value={lastname}
              name="lastname"
              readOnly={hasOrderParam && order.has_payment_transaction}
            />
          </div>
          <span className="ml-1 text-danger">{errors.last_name}</span>
        </div>
      </div>
      <div className="row m-0 mb-3">
        <div className="checkout-input-container">
          <img src={EmailIcon} alt="" style={{ filter: filterColor }} />
          <input
            onChange={onChangeText}
            type="text"
            placeholder="Correo"
            value={email}
            name="email"
            readOnly={hasOrderParam && order.has_payment_transaction}
          />
        </div>
        <span className="ml-1 text-danger">{errors.email}</span>
      </div>
      <div className="row">
        <div className="col-4 col-md-3 pe-md-2 pe-0 mb-3">
          <div className="checkout-input-container pt-1 pb-1">
            <span className="checkout-input-label">
              País
            </span>
            <Select
              placeholder="Selecciona"
              className="checkout-selector"
              classNamePrefix="checkout-selector"
              filterOption={filterCountries}
              isSearchable
              options={countriesPhonesOptions}
              value={dialCode ? [dialCode] : null}
              onChange={handleDialCodeChange}
            />
          </div>
        </div>
        <div className="col-8 col-md-9 ps-md-2 mb-3">
          <div className="checkout-input-container">
            <img src={PhoneIcon} alt="" style={{ filter: filterColor }} />
            <input
              onChange={onChangeText}
              type="text"
              placeholder="Número de teléfono"
              value={phone}
              name="phone"
              readOnly={hasOrderParam && order.has_payment_transaction}
            />
          </div>
          <span className="ml-1 text-danger">{errors.phone}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mb-3 pe-md-2">
          <div className="checkout-input-container pt-1 pb-1">
            <span className="checkout-input-label">
              Tipo de documento
            </span>
            {!isSmallDevice ? (
              <Select
                placeholder="Selecciona"
                className="checkout-selector"
                classNamePrefix="checkout-selector"
                isSearchable
                options={documentTypesOptions}
                value={documentType ? [documentType] : null}
                onChange={handleDocumentTypeChange}
              />) : (
              <BottomSheetModalOptions
                title="Tipo de documento"
                placeholder="Selecciona"
                name="documentType"
                value={documentType}
                options={documentTypesOptions}
                onSelect={handleDocumentTypeChange}
              />
            )}
          </div>
        </div>
        <div className="col-md-9 mb-3 ps-md-2">
          <div className="checkout-input-container flex-grow-4">
            <img src={IdIcon} alt="" style={{ filter: filterColor }} />
            <input
              onChange={onChangeText}
              type="text"
              placeholder="Identificación"
              defaultValue={documentId}
              name="documentId"
              readOnly={hasOrderParam && order.has_payment_transaction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationFields;
