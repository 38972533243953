export const ADD_LINE = 'ADD_LINE';
export const UPDATE_LINE = 'UPDATE_LINE';
export const REMOVE_LINE = 'REMOVE_LINE';
export const EMPTY_CART = 'EMPTY_CART';
export const CHANGE_USER_FIELD = 'CHANGE_USER_FIELD';
export const GET_RETAILER_INFO = 'GET_RETAILER_INFO';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_MORE_PRODUCTS = 'GET_MORE_PRODUCTS';
export const GET_ORDER = 'GET_ORDER';
export const ADD_ORDER_LINE = 'ADD_ORDER_LINE';
export const EMPTY_ORDER_CART = 'EMPTY_ORDER_CART';
export const SET_ORDER_FIELDS = 'SET_ORDER_FIELDS';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const GET_STRIPE_PAYMENT_SECRET = 'GET_STRIPE_PAYMENT_SECRET';
export const EMPTY_ORDER_CUSTOM_FIELDS = 'EMPTY_ORDER_CUSTOM_FIELDS';
export const ADD_ORDER_CUSTOM_FIELD = 'ADD_ORDER_CUSTOM_FIELD';
export const CHANGE_ORDER_CUSTOM_FIELD = 'CHANGE_ORDER_CUSTOM_FIELD';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const ADD_ALL_ORDER_CUSTOM_FIELD = 'ADD_ALL_ORDER_CUSTOM_FIELD';
export const SET_END_CUSTOM_FIELDS_REQUEST = 'SET_END_CUSTOM_FIELDS_REQUEST';
export const GET_MORE_PRODUCTS_FOR_VIEW_MORE = 'GET_MORE_PRODUCTS_FOR_VIEW_MORE';
export const EMPTY_ALL_PRODUCTS = 'EMPTY_ALL_PRODUCTS';
export const START_REQUEST_ALL_PRODUCTS = 'START_REQUEST_ALL_PRODUCTS';
export const END_REQUEST_ALL_PRODUCTS = 'END_REQUEST_ALL_PRODUCTS';
export const GET_ORDER_FOR_CHECKOUT = 'GET_ORDER_FOR_CHECKOUT';
export const START_SAVING_ORDER_REQUEST = 'START_SAVING_ORDER_REQUEST';
export const END_SAVING_ORDER_REQUEST = 'END_SAVING_ORDER_REQUEST';
export const SET_ORDER_SAVING_ERROR = 'SET_ORDER_SAVING_ERROR';
export const SET_ORDER_SAVED = 'SET_ORDER_SAVED';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const ADD_REMOVED_LINE = 'ADD_REMOVED_LINE';
export const EMPTY_CART_AND_REMOVED_LINES = 'EMPTY_CART_AND_REMOVED_LINES';
export const SET_SHIPPING_COSTS = 'SET_SHIPPING_COSTS';
