import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { find, isEmpty } from 'lodash';

import { formatMoney } from '../../../utils/number';
import InfoIcon from '../../../../assets/images/info.svg';
import IconDelivery from '../../../../assets/images/icon-delivery';

const ShippingCost = ({
  errors,
  onChange,
  shippingCostMethod
}) => {
  const filterColor =
    "brightness(0) saturate(100%) invert(55%) sepia(100%) saturate(319%) hue-rotate(156deg) brightness(90%) contrast(95%)";

  const [shippingCostOptions, setShippingCostOptions] = useState([]);
  const [selectedShippingCost, setSelectedShippingCost] = useState(null);

  const { shipping_cost_id, order = {} } = useSelector((reduxState) => reduxState.cartReducer);
  const { shippingCosts } = useSelector((reduxState) => reduxState.shippingCostReducer);

  useEffect(() => {
    if (shippingCosts == null) return;

    const options = shippingCosts.map((opt) => ({
      value: opt.id,
      label: formatLabel(opt),
      cost: opt.cost,
      message: opt.message,
      shipping_type: opt.shipping_type
    }));

    setShippingCostOptions(options);
    addShippingCost(options);
  }, [shippingCosts]);

  const addShippingCost = (options) => {
    let shippingCost;

    if (shippingCostMethod === 'fixed_cost') {
      shippingCost = find(options, { shipping_type: shippingCostMethod });
    } else if (shippingCostMethod === 'location_cost' && shipping_cost_id) {
      shippingCost = find(options, { value: shipping_cost_id });
    };

    let newShippingValue = 0;

    if (shippingCost) {
      setSelectedShippingCost(shippingCost);
      onChange("shipping_cost_id", shippingCost.value);
      newShippingValue = shippingCost.cost;
    } else {
      setSelectedShippingCost(null);
      onChange("shipping_cost_id", null);
      onChange("shipping_value", 0);
    }

    onChange("shipping_value", order.web_id ? order.shipping_value : newShippingValue);
  };

  const formatLabel = (item) => {
    if (item.shipping_type === "location_cost") {
      return `${item.location_name} - ${formatMoney(item.cost)}`;
    }
    return `${formatMoney(item.cost)}`;
  };

  const handleShippingValueChange = (selectedOption) => {
    setSelectedShippingCost(selectedOption);
    onChange("shipping_cost_id", selectedOption.value);
    onChange("shipping_value", selectedOption.cost);
  };

  const renderCostInfo = () =>
    selectedShippingCost?.message && (
      <div className="d-flex align-items-center mt-3 mt-lg-4">
        <img src={InfoIcon} className="me-2" style={{ filter: filterColor, width: 20 }} />
        <span className="text-blue fw-semibold fs-6 lh-1">
          {selectedShippingCost.message}
        </span>
      </div>
    );

  const locationCostView = () => {
    if (isEmpty(shippingCostOptions)) {
      return (<></>)
    };

    return (
      <div className="row mb-3 mt-3 mb-md-0 ">
        <div className="col-md-12 mb-3 mb-md-0 pe-md-1">
          <h5 className="fw-bold mb-3 pt-1 pt-md-0">Tarifa de envío</h5>
          <div className="checkout-input-container d-flex flex-row">
            <img
              src={IconDelivery}
              className="me-3"
              style={{ filter: filterColor }}
            />
            <Select
              placeholder="Selecciona"
              className="checkout-selector w-100"
              classNamePrefix="checkout-selector"
              options={shippingCostOptions}
              value={[shippingCostOptions.find((el) => el.value === shipping_cost_id)]}
              onChange={handleShippingValueChange}
            />
          </div>
          <span className="ml-1 text-danger">{errors.shipping_cost_id}</span>
        </div>
        {renderCostInfo()}
      </div>
    )
  }

  return (
    <>
      {shippingCostMethod === "location_cost"
        ? locationCostView()
        : renderCostInfo()}
    </>
  );
};

export default ShippingCost;
