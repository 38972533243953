import React, { memo, useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { geocoderLocationInfo } from '../../utils/util';

import PinIcon from '../../../assets/images/Pin-outline.svg';

const MapComponent = ({
  filterColor,
  toggleModal,
  isSmallDevice
}) => {
  const DEFAULT_ZOOM = 16;

  const containerStyle = {
    width: isSmallDevice ? '100%' : '500px',
    height: '60vh'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV?.GOOGLE_API_KEY
  });

  const {
    address,
    city,
    state,
    zipCode,
    country_id,
    latitude,
    longitude
  } = useSelector((reduxState) => reduxState.cartReducer);

  const defaultPosition = { lat: Number(latitude) || -0.190599, lng: Number(longitude) || -78.485619 };

  const [map, setMap] = useState(null);
  const [newAddress, setNewAddress] = useState({ address, city, state, zipCode, country_id });
  const [markerPosition, setMarkerPosition] = useState(defaultPosition);
  const [autoComplete, setAutoComplete] = useState(null);
  const [center, setCenter] = useState(defaultPosition);

  useEffect(() => {
    loadLocation();
  }, []);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, []);

  useEffect(() => {
    if (!map) return;

    if (latitude && longitude) {
      const newPosition = { lat: Number(latitude), lng: Number(longitude) };
      setMarkerPosition(newPosition);
      map && map.setCenter(newPosition);
    } else {
      loadLocation();
    }
  }, [map]);

  const handelClickOnMap = (e) => {
    const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    getHumanAddress(newPosition);
    setMarkerPosition(newPosition);
  };

  const loadLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position && position.coords && position.coords.latitude && position.coords.longitude) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const newPosition = { lat, lng };

          getHumanAddress(newPosition);
          setMarkerPosition(newPosition);
          map && map.setCenter(newPosition);
        };
      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const onLoadAutocomplete = (service) => {
    setAutoComplete(service);
  };

  const onPlaceChanged = () => {
    if (isEmpty(autoComplete)) return;

    const place = autoComplete.getPlace();

    if (!place) return;

    if (!place.geometry || !place.geometry.location) {
      alert('Esta ubicación no está disponible')
    };

    if (place.geometry.viewport || place.geometry.location) {
      const newPosition = { lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng() };

      map && map.setCenter(newPosition);
      getHumanAddress(newPosition);
      setMarkerPosition(newPosition);
    };
  };

  const getHumanAddress = (newPosition) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: newPosition }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const locationInfo = geocoderLocationInfo(results);
        setNewAddress({ ...newAddress, ...locationInfo });
      };
    });
  };

  const handelSave = () => {
    const addressData = {
      ...newAddress,
      latitude: markerPosition.lat,
      longitude: markerPosition.lng
    };

    toggleModal(addressData);
  };

  return (
    <div className="mercately-modal">
      <div className="modal__header d-none d-md-block">
        <div className="d-flex justify-content-between align-items-center fz-14 px-20 pt-30">
          <div className="modal__title">
            Selecciona tu dirección
          </div>
          <button type="button" className="btn-close d-none d-md-block" onClick={() => toggleModal(null)} />
        </div>
      </div>

      <div className="mt-20">
        <div className="checkout-input-container d-flex flex-row">
          <img src={PinIcon} alt="" style={{ filter: filterColor, marginTop: '8px' }} />
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
            className="w-100"
          >
            <input
              type="text"
              placeholder="Dirección"
              style={{
                border: `none`,
                width: `100%`,
                height: `32px`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`
              }}
              value={newAddress.address}
              onChange={(e) => setNewAddress({ ...newAddress, address: e.target.value })}
            />
          </Autocomplete>
        </div>
        {isLoaded && (
          <div className="mt-20">
            <GoogleMap
              id='google-map-script'
              onLoad={onLoad}
              center={center}
              zoom={DEFAULT_ZOOM}
              mapContainerStyle={containerStyle}
              onClick={handelClickOnMap}
              onUnmount={onUnmount}
              options={{
                streetViewControl: false,
                fullscreenControl: false
              }}
            >
              <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={handelClickOnMap}
              />
            </GoogleMap>
          </div>
        )}
      </div>

      <div className="modal__footer">
        <div className="px-20 mt-20 d-flex justify-content-between">
          <button type="button"
            onClick={() => toggleModal(null)}
            className="btn bg-light col-5 new-btn">
            Cancelar
          </button>

          <button type="button"
            onClick={handelSave}
            className="btn btn-main text-white col-5 new-btn">
            Guardar
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(MapComponent);