import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Global from './style/Global';

import Catalog from './components/Catalog';
import Checkout from './components/Checkout';
import OrderConfirmation from './components/OrderConfirmation';
import OrderError from './components/OrderError';
import Product from './components/Product';

const customHistory = createBrowserHistory();

const AppRoutes = ({ store }) => (
  <Provider store={store}>
    <Global />
    <Suspense fallback="loading">
      <Router history={customHistory}>
        <Route
          exact
          path={['/', '/catalog', '/catalog/:categoryId']}
          component={Catalog}
        />
        <Route
          exact
          path="/product/:productId"
          component={Product}
        />
        <Route
          path="/checkout"
          component={Checkout}
        />
        <Route
          exact
          path="/order/confirmation"
          component={OrderConfirmation}
        />
        <Route
          exact
          path="/order/error"
          component={OrderError}
        />
      </Router>
    </Suspense>
  </Provider>
);

export default AppRoutes;
