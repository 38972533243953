import React from 'react';
import { useSelector } from 'react-redux';

import { formatMoney } from '../../../utils/number';

const CatalogProductItem = ({
  product,
  onSelectProduct
}) => {
  const {
    title,
    price,
    image_url: imageUrl
  } = product;
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);

  let image = '';

  if (imageUrl) {
    image = imageUrl.url;
  }

  const onClickProduct = () => {
    if (window.innerWidth > 760) {
      onSelectProduct(product);
    } else {
      window.location.href = `/product/${product.web_id}`;
    }
  };

  return (
    <div className="col-6 col-md-4 catalog-product-item pb-4">
      <div onClick={onClickProduct} role="button">
        <div className="catalog-product-item-image">
          <img src={image} alt="" />
        </div>
        <p className="pt-3 mb-1 catalog-product-item-name">{title}</p>
        <p className="catalog-product-item-price">
          {retailer.currency}{formatMoney(price)} {retailer.currency_code}
        </p>
        <button
          type="button"
          className="btn btn-main text-white d-none d-md-block"
        >
          Comprar
        </button>
      </div>
    </div>
  );
};

export default CatalogProductItem;
