import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { forEach, isEmpty } from 'lodash';
import Modal from 'react-modal';
import { Autocomplete } from '@react-google-maps/api';

import { countryOptions, filterCountries } from '../../../utils/countries';
import { geocoderLocationInfo } from '../../../utils/util';

import ShippingCost from './ShippingCost';
import { changeUserField } from '../../../actions/cart';
import printFilter from '../../../services/styles';
import PinIcon from '../../../../assets/images/Pin-outline.svg';
import InfoIcon from '../../../../assets/images/info.svg';
import MapComponent from '../../Shared/MapComponent';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    backdropFilter: 'blur(9px)',
    zIndex: 10
  }
};

const DeliveryFields = ({
  errors,
  isSmallDevice,
  openMobileMapModal
}) => {
  const dispatch = useDispatch();
  const {
    address,
    description,
    city,
    state,
    zipCode,
    country_id,
    deliveryMethod,
    order = {}
  } = useSelector((reduxState) => reduxState.cartReducer);
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [filterColor, setFilterColor] = useState(
    "invert(46%) sepia(73%) saturate(472%) hue-rotate(90deg) brightness(88%) contrast(88%)"
  );
  const [openedMapModal, setOpenedMapModal] = useState(false);
  const [autoComplete, setAutoComplete] = useState(null);
  const hasOrderParam = !!new URLSearchParams(window.location.search).get('order');

  const formatAddress = (location) => {
    let formattedAddress = location.address;
    if (location.city) formattedAddress += `, ${location.city}`;
    if (location.country) formattedAddress += `, ${location.country}`;
    return formattedAddress;
  };

  useEffect(() => {
    const newOptions = [];
    forEach(retailer.retailer_addresses, (retailerAddress) => {
      newOptions.push({
        value: retailerAddress.web_id,
        label: formatAddress(retailerAddress),
      });
    });

    setPickupOptions(newOptions);

    if (retailer) {
      setFilterColor(printFilter(retailer.shop_main_color));
    }
  }, [retailer]);

  const onChangeField = (field, data) => {
    dispatch(changeUserField(field, data));
  };

  const onChangeText = ({ target }) => {
    const { name: field, value } = target;
    onChangeField(field, value);
  };

  const openMapModal = () => {
    if (isSmallDevice) {
      openMobileMapModal();
    } else {
      setOpenedMapModal(true);
    }
  };

  const fillAddressData = (newAddress) => {
    onChangeField("address", newAddress.address);
    onChangeField("city", newAddress.city);
    onChangeField("state", newAddress.state);
    onChangeField("zipCode", newAddress.zip_code);
    onChangeField("country_id", newAddress.country_id);
    onChangeField("latitude", newAddress.latitude);
    onChangeField("longitude", newAddress.longitude);
  };

  const closeMapModal = (newAddress = null) => {
    if (!isEmpty(newAddress)) {
      fillAddressData(newAddress);
    }

    setOpenedMapModal(!openedMapModal);
  };

  const handleDialCodeChange = (selectedOption) => {
    dispatch(changeUserField("country_id", selectedOption.value));
  };

  const customFormatOptionLabel = ({ dialCode, flag, name }, { context }) => (
    <>
      {`${flag} `}
      {context === "menu" ? <>{dialCode} </> : null}
      {name}
    </>
  );

  const onLoadAutocomplete = (service) => {
    setAutoComplete(service);
  };

  const onPlaceChanged = () => {
    if (isEmpty(autoComplete)) return;

    const place = autoComplete.getPlace();

    if (!place) return;

    if (!place.geometry || !place.geometry.location) {
      alert("Esta ubicación no está disponible");
    }

    if (place.geometry.viewport || place.geometry.location) {
      const newPosition = {
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng()
      };

      loadPlace(newPosition);
    }
  };

  const loadPlace = (coordinates) => {
    const geocoder = new google.maps.Geocoder();
    const newPosition = {
      lat: Number(coordinates.latitude),
      lng: Number(coordinates.longitude)
    };

    geocoder.geocode({ location: newPosition }, (results, status) => {
      if (status === "OK" && results[0]) {
        const info = geocoderLocationInfo(results);

        fillAddressData(info);
      } else {
        console.log("No existe dirección");
      }
    });
  };

  if (deliveryMethod === "send_order" && retailer.active_send_order) {
    return (
      <>
        <div className="mb-3 mt-3">
          <div className="checkout-input-container d-flex flex-row">
            <img
              src={PinIcon}
              alt=""
              style={{ filter: filterColor, marginTop: "8px" }}
            />
            <Autocomplete
              onLoad={onLoadAutocomplete}
              onPlaceChanged={onPlaceChanged}
              fields={["address_components", "geometry", "icon", "name"]}
              className="w-100"
            >
              <input
                type="text"
                placeholder="Dirección"
                id="address"
                name="address"
                style={{
                  border: "none",
                  width: "100%",
                  height: "32px",
                  fontSize: "14px",
                  outline: "none",
                  textOverflow: "ellipses",
                }}
                value={address || ""}
                onChange={onChangeText}
                readOnly={hasOrderParam && order.has_payment_transaction}
              />
            </Autocomplete>
          </div>
          <span className="ml-1 text-danger">{errors.address}</span>
        </div>

        <div className="col-md-12 mb-3">
          <button
            type="button"
            className="btn-add-map-address"
            onClick={openMapModal}
          >
            <span>Buscar en el mapa</span>
          </button>
        </div>

        <div className="row mb-3 mt-3">
          <div className="col-md-6 mb-3 mb-md-0 pe-md-1">
            <div className="checkout-input-container">
              <img src={PinIcon} alt="" style={{ filter: filterColor }} />
              <input
                onChange={onChangeText}
                type="text"
                placeholder="Descripción (casa, edificio, etc)"
                value={description}
                name="description"
                readOnly={hasOrderParam && order.has_payment_transaction}
              />
            </div>
            <span className="ml-1 text-danger">{errors.description}</span>
          </div>
          <div className="col-md-6 mb-3 mb-md-0 pe-md-1">
            <div className="checkout-input-container">
              <img src={PinIcon} alt="" style={{ filter: filterColor }} />
              <input
                onChange={onChangeText}
                type="text"
                placeholder="Ciudad"
                value={city}
                name="city"
                readOnly={hasOrderParam && order.has_payment_transaction}
              />
            </div>
            <span className="ml-1 text-danger">{errors.city}</span>
          </div>
        </div>

        <div className="row mb-3 mt-3">
          <div className="col-md-6 mb-3 mb-md-0 pe-md-1">
            <div className="checkout-input-container">
              <img src={PinIcon} alt="" style={{ filter: filterColor }} />
              <input
                onChange={onChangeText}
                type="text"
                placeholder="Estado o provincia"
                value={state}
                name="state"
                readOnly={hasOrderParam && order.has_payment_transaction}
              />
            </div>
            <span className="ml-1 text-danger">{errors.state}</span>
          </div>
          <div className="col-md-6 mb-3 mb-md-0 pe-md-1">
            <div className="checkout-input-container">
              <img src={PinIcon} alt="" style={{ filter: filterColor }} />
              <input
                onChange={onChangeText}
                type="text"
                placeholder="Código postal"
                value={zipCode}
                name="zipCode"
                readOnly={hasOrderParam && order.has_payment_transaction}
              />
            </div>
            <span className="ml-1 text-danger">{errors.zipCode}</span>
          </div>
        </div>

        <div className="row mb-3 mt-3 mb-md-0 ">
          <div className="col-md-6 mb-3 mb-md-0 pe-md-1">
            <div className="checkout-input-container d-flex flex-row">
              <img src={PinIcon} alt="" style={{ filter: filterColor }} />
              <Select
                placeholder="País"
                className="checkout-selector w-100"
                classNamePrefix="checkout-selector"
                filterOption={filterCountries}
                isSearchable
                options={countryOptions}
                formatOptionLabel={customFormatOptionLabel}
                value={[countryOptions.find((el) => el.value === country_id)]}
                onChange={handleDialCodeChange}
              />
            </div>
            <span className="ml-1 text-danger">{errors.country_id}</span>
          </div>
        </div>

        <ShippingCost
          errors={errors}
          shippingCostMethod={retailer?.shipping_cost_method}
          filterColor={filterColor}
          onChange={(name, value) => onChangeField(name, value)}
        />

        {openedMapModal && (
          <Modal
            isOpen={true}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <MapComponent
              filterColor={filterColor}
              toggleModal={closeMapModal}
              isSmallDevice={isSmallDevice}
            />
          </Modal>
        )}
      </>
    );
  }

  if (deliveryMethod === "store_pickup" && retailer.active_store_pickup) {
    return (
      <div className="row m-0 mb-3 mt-3">
        {/* <div className="checkout-input-container pt-1 pb-1"> */}
        {/*  <span className="checkout-input-label"> */}
        {/*    Elige una sucursal */}
        {/*  </span> */}
        {/*  <Select */}
        {/*    placeholder="Selecciona" */}
        {/*    className="checkout-selector" */}
        {/*    classNamePrefix="checkout-selector" */}
        {/*    isSearchable */}
        {/*    options={pickupOptions} */}
        {/*    defaultValue={store} */}
        {/*    onChange={onChangePickupStore} */}
        {/*  /> */}
        {/* </div> */}
      </div>
    );
  }
  return null;
};

export default DeliveryFields;
