import React from 'react';
import { useSelector } from 'react-redux';
import StepOne from '../../../../assets/images/step-one.svg';
import StepOneSelected from '../../../../assets/images/step-one-selected.svg';
import StepTwo from '../../../../assets/images/step-two.svg';
import StepTwoSelected from '../../../../assets/images/step-two-selected.svg';
import StepThree from '../../../../assets/images/step-three.svg';
import StepThreeSelected from '../../../../assets/images/step-three-selected.svg';

const CheckoutSteps = ({ currentStep, onChangeStep }) => {
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);
  return (
    <div className="d-flex d-md-none justify-content-evenly position-relative checkout-steps mb-4">
      <div className="text-center">
        <div className="checkout-step" onClick={() => onChangeStep(0)}>
          <img src={currentStep === 0 ? StepOneSelected : StepOne} alt="" className="mb-2" />
          <span className={currentStep === 0 ? 'selected' : ''}>
            Información básica
          </span>
        </div>
      </div>
      {(retailer.active_send_order || retailer.active_store_pickup) && (
        <div className="text-center">
          <div className="checkout-step" onClick={() => onChangeStep(1)}>
            <img src={currentStep === 1 ? StepTwoSelected : StepTwo} alt="" className="mb-2" />
            <span className={currentStep === 1 ? 'selected' : ''}>
              Entrega del pedido
            </span>
          </div>
        </div>
      )}
      <div className="text-center">
        <div className="checkout-step" onClick={() => onChangeStep(2)}>
          <img src={currentStep === 2 ? StepThreeSelected : StepThree} alt="" className="mb-2" />
          <span className={currentStep === 2 ? 'selected' : ''}>
            Método de pago
          </span>
        </div>
      </div>
      {(retailer.active_send_order || retailer.active_store_pickup) && (
        <div className="checkout-steps-line" />
      )}
    </div>
  );
}

export default CheckoutSteps;
