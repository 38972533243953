// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import 'bootstrap';
import '../i18n';

import React from 'react';
import ReactDOM from 'react-dom';

import configureStore from '../store/configureStore';

import AppRoutes from '../AppRoutes';
import { loadConfig } from '../config';

export const { store } = configureStore();

document.addEventListener('DOMContentLoaded', async () => {
  await loadConfig();
  ReactDOM.render(
    <AppRoutes store={store} />,
    document.getElementById('react_content')
  );
});
