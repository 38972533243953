import { cloneDeep, find } from 'lodash';
import {
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_PRODUCT,
  GET_MORE_PRODUCTS,
  SEARCH_PRODUCTS,
  GET_MORE_PRODUCTS_FOR_VIEW_MORE,
  EMPTY_ALL_PRODUCTS,
  START_REQUEST_ALL_PRODUCTS,
  END_REQUEST_ALL_PRODUCTS
} from '../actions/types';

const initialState = {
  categories: [],
  subcategories: [],
  totalPages: 0,
  product: {},
  products: [],
  submitted: false,
  allProducts: [],
  totalPagesAllProducts: 0,
  loadingAllProducts: false
};

const catalogReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_CATEGORIES: {
      return {
        ...state,
        categories: payload
      };
    }
    case GET_SUBCATEGORIES: {
      let updatedData;
      if (payload.append) {
        updatedData = cloneDeep(state.subcategories);
        updatedData.push(...payload.subcategories);
      } else {
        updatedData = payload.subcategories;
      }

      return {
        ...state,
        subcategories: updatedData,
        totalPages: payload.total_pages,
        submitted: false
      };
    }
    case GET_PRODUCT: {
      return {
        ...state,
        product: payload
      };
    }
    case 'TOGGLE_SUBMITTED':
      return {
        ...state,
        submitted: payload.submitted
      };
    case GET_MORE_PRODUCTS: {
      const updatedData = cloneDeep(state.subcategories);
      const currentSubcategory = find(updatedData, { web_id: payload.web_id });

      if (currentSubcategory) {
        currentSubcategory.products.push(...payload.products);
      }

      return {
        ...state,
        subcategories: updatedData
      };
    }
    case SEARCH_PRODUCTS: {
      return {
        ...state,
        products: payload
      };
    }
    case GET_MORE_PRODUCTS_FOR_VIEW_MORE: {
      return {
        ...state,
        allProducts: state.allProducts.concat(...payload.products),
        totalPagesAllProducts: payload.totalPages
      };
    }
    case START_REQUEST_ALL_PRODUCTS: {
      return {
        ...state,
        loadingAllProducts: true
      };
    }
    case END_REQUEST_ALL_PRODUCTS: {
      return {
        ...state,
        loadingAllProducts: false
      };
    }
    case EMPTY_ALL_PRODUCTS: {
      return {
        ...state,
        allProducts: [],
        totalPagesAllProducts: 0
      };
    }
    default:
      return state;
  }
};

export default catalogReducer;
