import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import CatalogProductItem from '../CatalogProductItem';

import { getProductsBySubcategoryForViewMore } from "../../../actions";

import { EMPTY_ALL_PRODUCTS } from "../../../actions/types";

const CatalogProducts = ({
  category,
  onSelectProduct
}) => {
  const dispatch = useDispatch();

  const {
    allProducts,
    totalPagesAllProducts,
    loadingAllProducts
  } = useSelector((reduxState) => reduxState.catalogReducer);

  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch({type: EMPTY_ALL_PRODUCTS});

    if (!category || isEmpty(category)) {
      setPage(0);
      return;
    }

    if (page == 1) {
      getProducts();
    } else {
      setPage(1);
    }
  }, [category]);

  useEffect(() => {
    if (page == 0) return;

    getProducts();
  }, [page]);

  const getProducts = () => {
    const params = {page: page, items_by_page: 15};
    dispatch(getProductsBySubcategoryForViewMore(category.web_id, params));
  }

  const viewMore = () => {
    setPage((prevValue) => prevValue + 1);
  }

  return (
    <div className="catalog-products bg-white">
      <h4 className="fw-bold pt-3 mb-0 pb-3">{category.name}</h4>
      <div className="row pt-3">
        {allProducts.map((product) => (
          <CatalogProductItem
            key={product.web_id}
            product={product}
            onSelectProduct={onSelectProduct}
          />
        ))}
      </div>

      {page < totalPagesAllProducts && (
        <div className="row pt-3">
          <span className="text-center">
            {loadingAllProducts && <FontAwesomeIcon icon={faSpinner} className="fa-spin m-mr-8" />}
            {!loadingAllProducts && (<a onClick={viewMore} className="text-main text-decoration-none cursor-pointer">Ver más</a>)}
          </span>
        </div>
      )}
    </div>
  );
}

export default CatalogProducts;
