import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRetailerInfo } from '../actions/retailer';

const Global = () => {
  const dispatch = useDispatch();

  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);

  useEffect(() => {
    dispatch(getRetailerInfo());
  }, []);

  const css = `
    .text-main {
      color: ${retailer.shop_main_color};
    }
    .btn-main {
      background-color: ${retailer.shop_main_color};
      color: ${retailer.font_color};
    }

    .product-image-carousel-indicators.carousel-indicators .active {
      background-color: ${retailer.shop_main_color};
      color: ${retailer.font_color};
    }

    .selected-category {
      background-color: ${retailer.shop_main_color} !important;
      color: ${retailer.font_color};
    }

    .checkout-input-label {
      color: ${retailer.shop_main_color};
    }

    .s-btn-secondary {
      color: ${retailer.shop_main_color} !important;
      border: 1px solid ${retailer.shop_main_color} !important;
    }

    @media (max-width: 760px) {
      .checkout-step .selected {
        color: ${retailer.shop_main_color};
      }
    }
  `;

  return (
    <style>{css}</style>
  );
};

export default Global;
