import { CATEGORIES, SUBCATEGORIES, ORDERS, PRODUCTS } from './urls';

export const getCategories = () => fetch(CATEGORIES);

export const getCatalogs = (params = {}) => {
  const encodedParams = new URLSearchParams(params).toString();
  return fetch(`${SUBCATEGORIES}?${encodedParams}`);
};

export const getProductsBySubcategory = (subcategoryWebId, params = {}) => {
  const encodedParams = new URLSearchParams(params).toString();
  return fetch(`/subcategories/${subcategoryWebId}/products?${encodedParams}`);
};

export const createOrder = (params = {}, csrfToken) => {
  let options = {
    method: 'POST',
    headers: {
      "X-CSRF-Token": csrfToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };
  return fetch(ORDERS, options);
};

export const renewTransactionId = (params = {}, csrfToken) => {
  let options = {
    method: 'POST',
    headers: {
      "X-CSRF-Token": csrfToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };
  return fetch(`${ORDERS}/renew_payphone_transaction_id`, options);
};

export const updateOrder = (params = {}, csrfToken) => {
  let options = {
    method: 'PUT',
    headers: {
      "X-CSRF-Token": csrfToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };
  return fetch(`${ORDERS}/${params.web_id}`, options);
};

export const searchProducts = (params = {}) => {
  const encodedParams = new URLSearchParams(params).toString();
  return fetch(`${PRODUCTS}?${encodedParams}`);
};
