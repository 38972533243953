import React, { useEffect } from 'react';
import { Big } from 'big.js';
import { useSelector } from 'react-redux';
import { renewTransactionId } from '../../../services';

const PayphoneButton = ({ order }) => {
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);
  const { shipping_value } = useSelector((reduxState) => reduxState.cartReducer);
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  useEffect(() => {
    initializePayphone();
  }, []);

  const initializePayphone = () => {
    const params = { id: order.web_id };

    renewTransactionId(params, csrfToken).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to renew transaction id');
    }).then((data) => {
      loadPaymentComponent(order, data.transaction_id);
    });
  };

  const loadPaymentComponent = (order, transactionId) => {
    const shippingValue = order.shipping_value || shipping_value;
    let noTaxAmount = order.tax_amount == 0 ? Number(Big(Number(order.subtotal)).times(100)) : 0;
    const taxAmount = order.tax_amount > 0 ? Number(Big(Number(order.subtotal)).times(100)) : 0;
    const totalAmount = Number(Big(Number(order.total)).times(100));
    const totalTaxAmount = Number(Big(Number(order.tax_amount)).times(100));
    noTaxAmount += shippingValue > 0 ? Number(Big(Number(shippingValue)).times(100)) : 0;

    const params = {
      token: ENV.PAYPHONE_TOKEN,
      amount: totalAmount,
      amountWithoutTax: noTaxAmount,
      amountWithTax: taxAmount,
      tax: totalTaxAmount,
      service: 0,
      tip: 0,
      storeId: "",
      reference: `Venta ${order.web_id} retailer: ${retailer.name}`,
      clientTransactionId: transactionId
    };
    window.ppb = new PPaymentButtonBox(params).render('pp-button');
  };

  return (
    <div id="pp-button">
    </div>
  );
}

export default PayphoneButton;
