import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProductModal from '../Catalog/CatalogProducts/ProductModal';
import { getProduct } from '../../actions';

const Product = ({ match: { params: { productId } } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { product } = useSelector((reduxState) => reduxState.catalogReducer);

  useEffect(() => {
    if (productId) {
      dispatch(getProduct(productId));
    }
  }, [productId]);

  const onDismissModal = () => {
    if (document.referrer.includes('catalog') && document.referrer.includes('mercately')) {
      history.goBack();
    } else {
      window.location.href = '/';
    }
  };

  return (
    <div className="h-100">
      <div className="min-vh-100">
        <ProductModal
          product={product}
          onDismiss={onDismissModal}
          isSmallDevice
        />
      </div>
    </div>
  );
};

export default Product;
