import Color from './color';
import Solver from './solver';
import hexToRgb from './hexToRgb';

// Transform Hex colors to filter
// the SVG file must be filled with black to make it work
const printFilter = (hex) => {
  // Defaults to Shops green base color
  if (!hex) return 'invert(46%) sepia(73%) saturate(472%) hue-rotate(90deg) brightness(88%) contrast(88%)';

  const rgb = hexToRgb(hex);
  const color = new Color(rgb[0], rgb[1], rgb[2]);
  const solver = new Solver(color);
  return solver.solve().filter;
}

export default printFilter;
