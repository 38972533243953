import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { forEach, find, isEmpty, cloneDeep, without } from 'lodash';
import Big from 'big.js';

import CloseModalIcon from 'images/cerrar.svg';
import BackIcon from 'images/Arrow-ios-back-fill.svg';

import CatalogProductQuantity from '../../CatalogProductQuantity';
import ProductImagesCarousel from './ProductImagesCarousel';
import { addLine, removeLine, updateLine } from '../../../../actions/cart';
import BottonSheetModalOptions from '../../../Checkout/BottonSheetModalOptions';
import { formatMoney } from '../../../../utils/number';

const ProductModal = ({ product, onDismiss, isSmallDevice }) => {
  const {
    title, price = 0, description = '', image_urls: images = []
  } = product;

  const currentLine = useSelector((reduxState) => {
    const { orderLines = [] } = reduxState.cartReducer;
    return orderLines.find((line) => line.product.web_id === product.web_id);
  });
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);

  const [subtotal, setSubtotal] = useState(price);
  const [quantity, setQuantity] = useState(1);
  const [variants, setVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [openedMobilModalOptions, setOpenedMobilModalOptions] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setSubtotal(Number(Big(Number(price)).times(Big(Number(quantity)))));
  }, [quantity, price]);

  const buildVariantsOptions = () => {
    let newVariants = [];
    let newSelectVariants = [];

    forEach(product.product_variants, item => {
      let newVariant = {
        id: item.id,
        name: item.name,
        selected_variant_option_id: null,
        options: [{ value: 'none', label: 'Ninguno', id: null, name: '', variant_id: item.id, variant_name: item.name }]
      };

      let optOptions = item.product_variant_options.map(opt => {
        return {
          value: opt.id,
          label: opt.name,
          id: opt.id,
          name: opt.name,
          variant_id: item.id,
          variant_name: item.name
        }
      });

      newVariant.options = newVariant.options.concat(optOptions);

      newVariants.push(newVariant);
    });

    setSelectedVariants(newSelectVariants);
    setVariants(newVariants);
  }

  useEffect(() => {
    if (product.web_id) {
      buildVariantsOptions();
      const el = document.getElementById('productModal');
      if (el) {
        const pModal = new Modal(el);
        pModal.show();
        el.addEventListener('hidden.bs.modal', onDismiss);
        el.addEventListener('hide.bs.modal', onDismiss);

        return () => el.removeEventListener('hidden.bs.modal', onDismiss);
      }
    } else {
      setQuantity(1);
    }
    return () => { };
  }, [product.web_id]);

  const addProductToCart = (e, close = false) => {

    e.preventDefault();
    dispatch(addLine(product, quantity, retailer.tax_amount, selectedVariants));
    if (close) {
      const el = document.getElementById('productModal');
      if (el) {
        const pModal = Modal.getInstance(el);
        pModal.hide();
      }
    } else {
      window.location.href = '/checkout';
    }
  };

  const onChangeQuantity = (newQuantity) => {
    if (newQuantity) {
      setQuantity(newQuantity);
    } else {
      setQuantity(1);
      if (currentLine) {
        dispatch(removeLine(currentLine.web_id));
      }
    }
  };

  const onClickAddAndPay = (e) => {
    addProductToCart(e, isSmallDevice);
  };

  const manageSelecetedVariants = (newSelectedVariants, variant, option) => {
    const params = { variant_id: variant.id, variant_option_id: variant.selected_variant_option_id, _destroy: false };
    const lineIn = find(newSelectedVariants, params);

    if (lineIn) {
      if (!lineIn.order_item_variant_id) {
        newSelectedVariants = without(newSelectedVariants, lineIn);
      } else {
        const lineIndex = newSelectedVariants.lastIndexOf(lineIn);
        newSelectedVariants[lineIndex]._destroy = true;
      }
    }

    if (option.value == 'none') return;

    newSelectedVariants.push({
      order_item_variant_id: null,
      variant_id: option.variant_id,
      variant_name: option.variant_name,
      variant_option_id: option.value,
      variant_option_name: option.label,
      _destroy: false
    })
  }

  const handleVariantOptionSelect = (option) => {
    let newSelectedVariants = [...selectedVariants];

    const newVariants = variants.map(variant => {
      if (variant.id == option.variant_id) {
        manageSelecetedVariants(newSelectedVariants, variant, option);
        return { ...variant, selected_variant_option_id: option.value }
      } else {
        return variant;
      }
    });

    setSelectedVariants(newSelectedVariants);
    setVariants(newVariants);
  }

  return (
    <div className="modal product-modal" id="productModal">
      <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-md-down">
        <div className="modal-content catalog-product-modal">
          <button type="button" className="btn btn-close-modal" data-bs-dismiss="modal" aria-label="Close">
            <img src={CloseModalIcon} alt="" />
          </button>
          <div className="row ps-md-3 pe-md-3 m-0 product-modal-content-row" style={{ minHeight: 400, zIndex: openedMobilModalOptions ? 10 : 0 }} >
            <div className="col-12 col-md-5 product-modal-image-container">
              <button
                type="button"
                className="btn d-md-none product-modal-back"
                style={{ zIndex: openedMobilModalOptions ? 0 : 10 }}
                onClick={onDismiss}
              >
                <img src={BackIcon} alt="" />
              </button>
              {product.web_id && <ProductImagesCarousel images={images} isSmallDevice={isSmallDevice} />}
            </div>
            <div className="col-12 col-md-7 mt-md-4 border-start product-modal-container">
              <div className="pt-3 pt-md-2  ps-3 pe-3 product-modal-content">
                <h3 className="modal-title fw-bold border-bottom">{title}</h3>
                <h5 className="fw-bold pt-2 pb-4">
                  {retailer.currency}{formatMoney(price)} {retailer.currency_code}
                </h5>
                <div id="product-description">
                  {description.split('\n').map((desc, index) => (
                    <span key={index.toString()}>
                      {desc}
                      <br />
                    </span>
                  ))}
                </div>
                <div className='mt-4 mb-4 product-variant-container'>
                  {variants.map((variant, index) => (
                    <div key={`variant-select-${index}`} className={`${index > 0 ? 'pt-2' : ''} d-flex flex-row`}>
                      <div className='d-flex align-items-center mr-10'><span className='fw-bold'>{variant.name}:</span></div>
                      <div className="checkout-input-container pt-2 pb-1 bg-white rounded" style={{ width: '150px' }}>
                        {isSmallDevice ? (
                          <BottonSheetModalOptions
                            title="Variantes"
                            placeholder="Selecciona"
                            name="variants"
                            value={variant.options.find((el) => el.value == variant.selected_variant_option_id)}
                            options={variant.options}
                            onSelect={handleVariantOptionSelect}
                            setOpenedMobilModalOptions={setOpenedMobilModalOptions} />)
                          : (
                            <Select
                              value={[variant.options.find((el) => el.value == variant.selected_variant_option_id)]}
                              placeholder="Selecciona"
                              className="product-variant-selector"
                              classNamePrefix="product-variant-selector"
                              isSearchable
                              options={variant.options}
                              onChange={handleVariantOptionSelect}
                            />
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white product-modal-footer d-flex">
            <div className="flex-grow-1 d-flex flex-column flex-sm-row align-items-center product-modal-subtotal">
              {quantity > 0 && (
                <>
                  Subtotal
                  <h5 className="ps-sm-3 fw-bold mb-0">
                    {retailer.currency}{formatMoney(subtotal)} {retailer.currency_code}
                  </h5>
                </>
              )}
            </div>
            {quantity > 0 && <CatalogProductQuantity quantity={quantity} onChangeQuantity={onChangeQuantity} />}
            <button type="button" className="btn text-muted d-flex flex-row text-white me-md-1 s-btn-secondary" onClick={onClickAddAndPay}>
              Agregar
              {!isSmallDevice && ' y pagar'}
            </button>
            <button type="button" className="btn btn-main text-white d-none d-md-block" onClick={(e) => addProductToCart(e, true)}>
              Agregar y seguir comprando
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
