import React from 'react';

import ArrowIcon from 'images/Arrow-forward-fill.svg';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '../../../utils/number';

const StepFooter = ({ disabled, onClickNext, isFinalStep, hideStepButton }) => {
  const { subtotal, total, hasPaymentTransaction } = useSelector((reduxState) => reduxState.cartReducer);
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const { savingOrder } = useSelector((reduxState) => reduxState.orderReducer);

  return (
    <div className="position-fixed bottom-0 start-0 w-100 catalog-subtotal-footer">
      <div className="d-flex">
        <div className="flex-grow-1 d-flex">
          <div className="d-flex flex-column flex-md-row">
            {isFinalStep ? 'Total' : 'Subtotal'}
            <span className="ps-md-3">
              {retailer.currency}{formatMoney(isFinalStep ? total : subtotal)} {retailer.currency_code}
            </span>
          </div>
          <div className="flex-grow-1" />
        </div>
        {!hideStepButton && (!hasPaymentTransaction || !isFinalStep) && (
          <button
            type="button"
            className={`btn btn-main justify-content-center align-items-center text-white ${isFinalStep ? 'ps-5 pe-5' : 'ps-4 pe-4'} ps-md-5 pe-md-5`}
            onClick={onClickNext}
            disabled={savingOrder}
          >
            {isFinalStep && savingOrder && <FontAwesomeIcon icon={faSpinner} className="fa-spin m-mr-8" />}
            {isFinalStep ? 'Ordenar' : 'Siguiente'}
            {!isFinalStep && <img src={ArrowIcon} alt="" className="ms-2" />}
          </button>
        )}
      </div>
    </div>
  );
};

export default StepFooter;
