import React from 'react';

import ArrowIcon from 'images/Arrow-forward-fill.svg';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../../utils/number';

const SubtotalFooter = () => {
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);
  const { subtotal } = useSelector((reduxState) => reduxState.cartReducer);
  return (
    <div className="position-sticky bottom-0 catalog-subtotal-footer">
      <div className="d-flex">
        <div className="flex-grow-1 d-flex">
          <div className="d-flex flex-column flex-md-row">
            Subtotal
            <span className="ps-md-3">
              {retailer.currency}{formatMoney(subtotal)} {retailer.currency_code}
            </span>
          </div>
          <div className="flex-grow-1" />
        </div>
        <a className="btn btn-main justify-content-center align-items-center text-white ps-4 pe-4 ps-md-5 pe-md-5" role="button" href="/checkout">
          Pagar
          <img src={ArrowIcon} alt="" className="ms-2" />
        </a>
      </div>
    </div>
  );
};

export default SubtotalFooter;
