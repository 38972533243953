import httpService  from '../services/httpService';
import {
  getCategories as getCategoriesService,
  getCatalogs as getCatalogsService,
  getProductsBySubcategory as getProductsBySubcategoryService,
  createOrder as createOrderService,
  updateOrder as updateOrderService,
  searchProducts as searchProductsService
} from '../services';
import {
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_MORE_PRODUCTS,
  EMPTY_CART_AND_REMOVED_LINES,
  SEARCH_PRODUCTS,
  GET_MORE_PRODUCTS_FOR_VIEW_MORE,
  START_REQUEST_ALL_PRODUCTS,
  END_REQUEST_ALL_PRODUCTS
} from './types';

export const getCategories = () => (dispatch) => getCategoriesService()
  .then((res) => res.json())
  .then((categories) => {
    dispatch({ type: GET_CATEGORIES, payload: categories });
  });

export const getCatalogs = (params = {}) => (dispatch) => getCatalogsService(params)
  .then((res) => res.json())
  .then((data) => {
    const payload = { ...data, append: params.page > 1 };
    dispatch({ type: GET_SUBCATEGORIES, payload });
  });

export const getProductsBySubcategory = (web_id, params = {}) => (dispatch) => getProductsBySubcategoryService(web_id, params)
  .then((res) => res.json())
  .then((data) => {
    if (data.products.length) {
      const payload = {
        web_id,
        products: data.products
      };
      dispatch({ type: GET_MORE_PRODUCTS, payload });
    }
  });

export const createOrder = (params = {}, message, retailer, token) => (dispatch) => createOrderService(params, token)
  .then((res) => res.json())
  .then(() => {
    dispatch({ type: EMPTY_CART_AND_REMOVED_LINES });
    if (retailer.phone_number) {
      window.open(`https://api.whatsapp.com/send?phone=${retailer.phone_number.replace(/[/+\s]/g, '')}&text=${encodeURIComponent(message)}`, '_blank');
    }
    window.location.href = '/order/confirmation';
  });

export const updateOrder = (params = {}, message, retailer, token) => (dispatch) => updateOrderService(params, token)
  .then((res) => res.json())
  .then(() => {
    dispatch({ type: EMPTY_CART_AND_REMOVED_LINES });
    if (retailer.phone_number) {
      window.open(`https://api.whatsapp.com/send?phone=${retailer.phone_number.replace(/[/+\s]/g, '')}&text=${encodeURIComponent(message)}`, '_blank');
    }
    window.location.href = '/order/confirmation';
  });

export const searchProducts = (params = {}) => (dispatch) => searchProductsService(params)
  .then((res) => res.json())
  .then((data) => {
    if (data.products.length) {
      const payload = {
        products: data.products
      };
      dispatch({ type: SEARCH_PRODUCTS, payload: payload.products });
    }
  });

  export const getProductsBySubcategoryForViewMore = (web_id, params = {}) => {
    const encodedParams = new URLSearchParams(params).toString();
    const endpoint = `/subcategories/${web_id}/products?${encodedParams}`;

    return (dispatch) => {
      dispatch({type: START_REQUEST_ALL_PRODUCTS});
      httpService.get(endpoint).then(data => {
        dispatch({type: END_REQUEST_ALL_PRODUCTS});
        const payload = {
          web_id,
          products: data.products,
          totalPages: data.total_pages
        };
        dispatch({ type: GET_MORE_PRODUCTS_FOR_VIEW_MORE, payload });
      }).catch(() => {
        dispatch({type: END_REQUEST_ALL_PRODUCTS});
      });
    };
  };
