import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { SET_ORDER_SAVED, SET_SERVER_ERROR } from '../../../actions/types';

import ErrorIcon from '../../../../assets/images/error-icon.svg';
import IconWhatsapp from '../../../../assets/images/Whatsapp-line.svg';
import BackIcon from '../../../../assets/images/Arrow-ios-back-fill.svg';

const StatusMessage = () => {
  const dispatch = useDispatch();

  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const writeToStore = () => {
    dispatch({ type: SET_ORDER_SAVED, payload: {}});
    dispatch({ type: SET_SERVER_ERROR, payload: {}});

    if (isEmpty(retailer)) return;

    const whatsappUrl = retailer.phone_number && `https://api.whatsapp.com/send?l=es&phone=${retailer.phone_number.replace(/[/+\s]/g, '')}`;
    window.open(whatsappUrl);
  };

  const backToStore = () => {
    dispatch({ type: SET_ORDER_SAVED, payload: {}});
    dispatch({ type: SET_SERVER_ERROR, payload: {}});
    window.location.href = '/';
  }

  return (
    <>
      <img src={ErrorIcon} alt="" />
      <h3>Oops, algo salió mal con tu compra</h3>
      <span className="text-muted">
        No hemos podido realizar tu pedido. Por favor, comunícate con nosotros.
      </span>
      <button className="btn btn-success text-white d-flex justify-content-center align-items-center mt-4" onClick={writeToStore}>
        <img src={IconWhatsapp} alt="" className="me-2" />
        Escribir a la tienda
      </button>
      {retailer.active && (
        <button
          className="btn border-1 d-flex justify-content-center align-items-center mt-3"
          onClick={backToStore}
        >
          <img src={BackIcon} alt="" className="me-2" />
          Regresar al catálogo
        </button>
      )}
    </>
  );
};

export default StatusMessage;
