import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import moment from 'moment-timezone';

import FbIcon from 'images/fb-icon.png';
import IgIcon from 'images/ig-icon.png';
import TtIcon from 'images/tt-icon.png';
import TwIcon from 'images/tw-icon.png';
import WsbIcon from 'images/ws-icon.png';
import ClockIcon from 'images/Clock-outline.svg';
import PinIcon from 'images/Pin-outline.svg';

import SocialLabel from '../SocialLabel';
import AllAddressesModal from './AllAddressesModal';
import printFilter from '../../../services/styles';
import BottomSheetModal from '../../Checkout/BottomSheetModal';

const CatalogHeader = ({ isSmallDevice }) => {
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [officeHours, setOfficeHours] = useState();
  const [officeStatus, setOfficeStatus] = useState('closed');
  const [filterColor, setFilterColor] = useState('invert(46%) sepia(73%) saturate(472%) hue-rotate(90deg) brightness(88%) contrast(88%)');

  const { t } = useTranslation();

  useEffect(() => {
    if (retailer.retailer_schedules) {
      const schedule = find(retailer.retailer_schedules, { weekday: retailer.current_day, active: true });
      if (schedule) {
        let currentTime = retailer.timezone ? moment().tz(retailer.timezone) : moment();
        currentTime = currentTime.format('HH');
        if (currentTime >= moment(schedule.closing_time).utc().format('HH')) {
          setOfficeStatus('closed');
          setOfficeHours('');
        } else {
          setOfficeStatus('openNow');
          setOfficeHours(`${moment(schedule.opening_time).utc().format('hh:mm a')} - ${moment(schedule.closing_time).utc().format('hh:mm a')}`);
        }
      }
    }
  }, [retailer]);

  useEffect(() => {
    setFilterColor(printFilter('#FFF'));
  }, []);

  const openAllAddresses = () => {
    setShowAllAddresses((prevState) => !prevState);
  };

  const formatAddress = (location) => {
    let { address } = location;
    if (location.city) address += `, ${location.city}`;
    if (location.country) address += `, ${location.country}`;
    return address;
  };

  const hasBgImage = !!retailer.background_url;
  const whatsappUrl = retailer.phone_number && `https://api.whatsapp.com/send?phone=${retailer.phone_number.replace(/[/+\s]/g, '')}`;

  const renderSchedule = (isMobile) => (
    <>
      <img src={ClockIcon} alt="" className={`catalog-header-icon ${!hasBgImage && !isMobile ? 'text-black' : ''}`} style={{ filter: filterColor }} />
      <div className="ps-2">
        <p className="h5">{t('schedule')}</p>
        <div className={`mb-2 ${officeStatus === 'openNow' ? 'text-white' : 'text-danger'}`}>
          {t(officeStatus)}
        </div>
        <p>{officeHours}</p>
      </div>
    </>
  );

  const renderAddress = (isMobile) => (
    <>
      <img src={PinIcon} alt="" className={`catalog-header-icon ${!hasBgImage && !isMobile ? 'text-black' : ''}`} style={{ filter: filterColor }} />
      <div className="ps-2 col-md-12">
        <p className="h5">
          {t('address')}
        </p>
        <div className="catalog-header-address">
          {retailer.retailer_addresses?.length > 0 && (
            <span>
              {formatAddress(retailer.retailer_addresses[0])}
            </span>
          )}
          {retailer.retailer_addresses?.length > 1 && (
            <div className="row col-md-12 m-fs-12">
              <a
                href="#"
                onClick={() => {
                  if (isMobile) {
                    setShowMoreInfo(false);
                  }
                  openAllAddresses();
                }}
              >
                Ver todas las ubicaciones
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const renderSocialNetworks = (isMobile) => (
    <>
      {retailer.facebook_url && (
        <div className={`${isMobile ? '' : 'col-1'} col-md-12 col-lg-6 pe-0`}>
          <SocialLabel
            icon={FbIcon}
            name="Facebook"
            url={retailer.facebook_url}
            big={isMobile}
          />
        </div>
      )}
      {whatsappUrl && (
        <div className={`${isMobile ? '' : 'col-1'} col-md-12 col-lg-6 pe-0`}>
          <SocialLabel
            icon={WsbIcon}
            name="Whatsapp"
            url={whatsappUrl}
            big={isMobile}
          />
        </div>
      )}
      {retailer.instagram_url && (
        <div className={`${isMobile ? '' : 'col-1'} col-md-12 col-lg-6 pe-0`}>
          <SocialLabel
            icon={IgIcon}
            name="Instagram"
            url={retailer.instagram_url}
            big={isMobile}
          />
        </div>
      )}
      {retailer.tiktok_url && (
        <div className={`${isMobile ? '' : 'col-1'} col-md-12 col-lg-6 pe-0`}>
          <SocialLabel
            icon={TtIcon}
            name="Tik Tok"
            url={retailer.tiktok_url}
            big={isMobile}
          />
        </div>
      )}
      {retailer.twitter_url && (
        <div className={`${isMobile ? '' : 'col-1'} col-md-12 col-lg-6 pe-0`}>
          <SocialLabel
            icon={TwIcon}
            name="Twitter"
            url={retailer.twitter_url}
            big={isMobile}
          />
        </div>
      )}
    </>
  );

  return (
    <div className="col-12 header-container" style={hasBgImage ? { background: `url("${retailer.background_url}") center/cover no-repeat` } : {}}>
      {showAllAddresses && <AllAddressesModal toggleModal={openAllAddresses} addresses={retailer.retailer_addresses} /> }
      <div className={`catalog-header-content ${!hasBgImage ? 'text-black' : ''}`}>
        <div className="container d-flex d-md-block flex-row">
          {retailer.logo_url && (
            <Link className="text-decoration-none" to="/">
              <img
                className="catalog-header-avatar rounded-circle"
                src={retailer.logo_url}
                alt=""
              />
            </Link>
          )}
          <div>
            <Link className="text-decoration-none" to="/">
              <p className="h1 fw-bold catalog-header-title">
                {retailer.name || ''}
              </p>
            </Link>
            <div className="row">
              <div className="col-md-4 lh-lg">
                {retailer.description || ''}
                <div className="d-md-none">
                  <button onClick={() => setShowMoreInfo(true)} type="button" className="btn catalog-header-btn ps-0 mb-1">
                    {t('moreInfo')}
                  </button>
                </div>
              </div>
              <div className="col-md-3 col-lg-2 border-start border-end d-md-flex flex-row d-none">
                {renderSchedule()}
              </div>
              <div className="col-3 border-end d-md-flex flex-row d-none">
                {renderAddress()}
              </div>
              <div className="row col-lg-3 col-md-2 pe-0">
                {renderSocialNetworks()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomSheetModal
        visible={showMoreInfo}
        onDismiss={() => setShowMoreInfo(false)}
        name="information"
        title="Información"
      >
        <div className="pt-4 pb-4">
          <div className="d-flex">
            {renderSchedule(true)}
          </div>
          <div className="d-flex pt-2">
            {renderAddress(true)}
          </div>
          <div className="d-flex justify-content-between p-4 pb-0">
            {renderSocialNetworks(true)}
          </div>
        </div>
      </BottomSheetModal>
    </div>
  );
};

export default CatalogHeader;
