import {
  SET_CUSTOM_FIELDS,
  SET_END_CUSTOM_FIELDS_REQUEST
} from '../actions/types';

const initialState = {
  customFields: [],
  totalPages: 0,
  endCustomFieldsRequest: false
};

const customFieldReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_CUSTOM_FIELDS: {
      return {
        ...state,
        customFields: payload
      };
    }
    case SET_END_CUSTOM_FIELDS_REQUEST: {
      return {
        ...state,
        endCustomFieldsRequest: payload.status
      };
    }
    default:
      return state;
  }
};

export default customFieldReducer;
