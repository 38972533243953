import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forEach } from 'lodash';

import CatalogHeader from './CatalogHeader';
import CategoryMenu from './CategoryMenu';
import SearchBar from './SearchBar';
import CategoryProducts from './CategoryProducts';
import Cart from './Cart';
import SubtotalFooter from './SubtotalFooter';
import ProductsList from './ProductsList';

import {
  getCategories,
  getCatalogs,
  searchProducts
} from '../../actions';

import { EMPTY_ALL_PRODUCTS } from "../../actions/types";

const Catalog = ({ match: { params: { categoryId } } }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((reduxState) => reduxState.catalogReducer);
  const { subcategories = [], totalPages, submitted } = useSelector((reduxState) => reduxState.catalogReducer);
  const [displayMoreItemsButton, setDisplayMoreItemsButton] = useState(true);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 760);

  const onResizeWindow = () => {
    setIsSmallDevice(window.innerWidth <= 760);
  };

  useEffect(() => {
    dispatch(getCategories());
    window.addEventListener('resize', onResizeWindow);
    return () => window.removeEventListener('resize', onResizeWindow);
  }, []);

  useEffect(() => {
    const params = { page };
    dispatch(getCatalogs(params));
  }, [page]);

  useEffect(() => {
    if (!submitted && page === totalPages) {
      setDisplayMoreItemsButton(false);
    }
  }, [submitted]);

  useEffect(() => {
    setDisplayMoreItemsButton(page !== totalPages);
  }, [totalPages]);

  const handleMoreItems = () => {
    const payload = { submitted: true };
    dispatch({ type: 'TOGGLE_SUBMITTED', payload });
    setPage((prevValue) => prevValue + 1);
  };

  useEffect(() => {
    if (!categoryId) {
      setCategory({});
      return;
    }

    let subcategoryIn = null;

    forEach(categories, cat => {
      if (!subcategoryIn) {
        subcategoryIn = cat.subcategories.find((e) => e.web_id === categoryId);
      }
    });

    setCategory(subcategoryIn || {});
  }, [categoryId, categories]);

  const handleProductsSearch = () => {
    const params = { search: searchValue };
    dispatch(searchProducts(params));
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);

    if (e.key === 'Enter') {
      if (searchValue.trim() !== '') {
        handleProductsSearch();
        dispatch({type: EMPTY_ALL_PRODUCTS});
        setDisplaySearchResults(true);
      } else {
        setDisplaySearchResults(false);
      }
    }
  };

  const resetSearch = () => {
    setDisplaySearchResults(false);
    setSearchValue('');
    if (!isSmallDevice) document.getElementById('search-bar').value = '';
  };

  return (
    <div className="h-100">
      <div className="min-vh-100">
        {isSmallDevice && <SearchBar isSmallDevice={isSmallDevice} handleSearchValueChange={handleSearchValueChange} />}
        <div
          className="offcanvas offcanvas-start catalog-drawer"
          tabIndex="-1"
          id="drawerCategory"
          aria-labelledby="drawerLabel"
        >
          {isSmallDevice && (
          <CategoryMenu
            categories={categories}
            currentCategory={category}
            isSmallDevice={isSmallDevice}
            resetSearch={resetSearch}
          />
          )}
        </div>

        <Cart />

        <CatalogHeader isSmallDevice={isSmallDevice} />
        <div className="col-12 container" id="products">
          <div className="row">
            {!isSmallDevice && (
            <CategoryMenu
              categories={categories}
              currentCategory={category}
              isSmallDevice={isSmallDevice}
              resetSearch={resetSearch}
            />
            )}
            <div className="col-md-9 pb-5 category-products">
              {!isSmallDevice && <SearchBar isSmallDevice={isSmallDevice} handleSearchValueChange={handleSearchValueChange} />}
              { !displaySearchResults && (
              <CategoryProducts
                isSmallDevice={isSmallDevice}
                currentCategory={category}
                categories={subcategories}
                handleMoreItems={handleMoreItems}
                displayMoreItemsButton={displayMoreItemsButton}
                submitted={submitted}
              />
              ) }
              { displaySearchResults && (
              <ProductsList
                isSmallDevice={isSmallDevice}
              />
              ) }
            </div>
          </div>
        </div>
      </div>
      {isSmallDevice && <SubtotalFooter />}
    </div>
  );
};

export default Catalog;
