import { isEmpty } from "lodash";

export default class httpService {

  static async get(resource, params = {}) {
    if (!isEmpty(params)) {
      resource = `${resource}?${new URLSearchParams(params).toString()}`;
    }

    const options = {
      method: 'GET',
      headers: setHeader()
    };

    return request(resource, options);
  }

  static async save(resource, params, csrfToken = '', method = 'POST') {
    const options = {
      method: method,
      headers: setHeader(csrfToken),
      body: JSON.stringify(params)
    }

    return request(resource, options);
  }
}

function setHeader(csrfToken) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if (csrfToken) headers['X-CSRF-Token'] = csrfToken;

  return headers;
}

async function request(resource, options) {
  const response = await fetch(resource, options).catch(handleFetchError);
  const json = await response.json().catch(handleParsingError);

  checkResponse(response, json);
  return json;
}

function checkResponse(response, json) {
  if (response.status !== 200) {
    throw {
      errors: json.message || json.errors,
      status: response.status
    };
  }
}

function handleFetchError(error) {
  console.log("ERROR FETCHING: ", error);
  throw "We were unable to connect to the server";
}

function handleParsingError(error) {
  console.log("ERROR PARSING JSON: ", error);
  throw "There was an error reading the response from the server";
}