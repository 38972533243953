import React from 'react';
import StatusMessage from './StatusMessage';
import BottomSheetModal from '../Checkout/BottomSheetModal';

const OrderError = () => {
  return (window.innerWidth < 760 ? (
    <BottomSheetModal
      onDismiss={() => { window.location.href = '/'; }}
      className="order-confirmation-modal text-center"
      name="orderStatus"
      visible
    >
      <StatusMessage />
    </BottomSheetModal>
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="order-confirmation text-center d-flex flex-column justify-content-center align-items-center">
        <StatusMessage />
      </div>
    </div>
  ));
};

export default OrderError;
