import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { SET_ORDER_SAVED } from '../../../actions/types';

import IconSuccess from '../../../../assets/images/Checkmark-confirmation.svg';
import IconWhatsapp from '../../../../assets/images/Whatsapp-line.svg';
import BackIcon from '../../../../assets/images/Arrow-ios-back-fill.svg';

const StatusMessage = ({ paymentStatus }) => {
  const dispatch = useDispatch();

  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const verifyingPayment = paymentStatus === 'success' || paymentStatus === 'pending';

  const writeToStore = () => {
    dispatch({ type: SET_ORDER_SAVED, payload: {} });

    if (isEmpty(retailer)) return;

    const whatsappUrl = retailer.phone_number && `https://api.whatsapp.com/send?l=es&phone=${retailer.phone_number.replace(/[/+\s]/g, '')}`;
    window.open(whatsappUrl);
  };

  const backToStore = () => {
    dispatch({ type: SET_ORDER_SAVED, payload: {} });
    window.location.href = "/";
  };
  return (
    <>
      <img src={IconSuccess} alt="" />
      {verifyingPayment ? (
        <>
          <h3>¡Muchas gracias por tu compra!</h3>
          <span className="text-muted">
            Se ha realizado el pago de tu pedido. Estamos preparándolo para que
            puedas disfrutarlo.
          </span>
          <span className="text-muted mt-10">
            La tienda verificará que tu pago se haya procesado correctamente
          </span>
        </>
      ) : (
        <>
          <h3>¡Muchas gracias por tu pedido!</h3>
          <span className="text-muted">
            se ha realizado el pedido. Estamos preparándolo para que puedas disfrutarlo.
          </span>
        </>
      )}

      <button
        className="btn btn-success text-white d-flex justify-content-center align-items-center mt-4"
        onClick={writeToStore}
      >
        <img src={IconWhatsapp} alt="" className="me-2" />
        Escribir a la tienda
      </button>
      {retailer.active && (
        <button
          className="btn border-1 d-flex justify-content-center align-items-center mt-3"
          onClick={backToStore}
        >
          <img src={BackIcon} alt="" className="me-2" />
          Regresar al catálogo
        </button>
      )}
    </>
  );
};

export default StatusMessage;
