import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Collapse, Offcanvas } from 'bootstrap';
import ChevronIcon from 'images/Chevron-right-outline.svg';

const CategoryMenu = ({ categories, currentCategory, isSmallDevice, resetSearch }) => {
  useEffect(() => {
     if (currentCategory.web_id || (categories.length === 1 && categories[0].web_id && !currentCategory.web_id)) {
      const currentCategoryId = `categoryItem-${currentCategory.web_id || categories[0].web_id}`;
      const el = document.getElementById(currentCategoryId);
      if (el) {
        const collapseElementList = [].slice.call(document.querySelectorAll('.category-menu-items .collapse'));
        collapseElementList.forEach((collapseEl) => {
          if (collapseEl.id !== currentCategoryId) {
            Collapse.getOrCreateInstance(collapseEl).hide();
          }
        });
        Collapse.getOrCreateInstance(el).show();
      }
      if (isSmallDevice) {
        const offcanvasEl = document.getElementById('drawerCategory');
        if (offcanvasEl) {
          Offcanvas.getOrCreateInstance(offcanvasEl).hide();
        }
      }
    }
  }, [currentCategory, categories]);

  const hideMenu = () => {
    resetSearch();
    if (isSmallDevice) {
      const offcanvasEl = document.getElementById('drawerCategory');
      if (offcanvasEl) {
        Offcanvas.getOrCreateInstance(offcanvasEl).hide();
      }
    }
  }

  const renderCategories = () => categories.sort((a, b)=> (a.order - b.order)).map((category) => {
    const isSelected = (categoryItem) => categoryItem.web_id === currentCategory.web_id;
    const hasSelectedSubcategory = category.subcategories.some(isSelected);

    const onClickSelectedCategory = () => {
      const el = document.getElementById(`categoryItem-${category.web_id}`);
      if (el) {
        Collapse.getOrCreateInstance(el).toggle();
      }
    };

    return (
      <div key={category.web_id}>
        <button
          className="btn w-100 category-menu-item"
          onClick={onClickSelectedCategory}
        >
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 text-start">
              {category.name}
            </div>
            <img src={ChevronIcon} alt="" />
          </div>
        </button>
        <div className={`collapse ${hasSelectedSubcategory ? 'show' : ''}`} id={`categoryItem-${category.web_id}`}>
          <div className="d-flex flex-column ps-2">
            {category.subcategories.sort((a, b)=> (a.order - b.order)).map((subcategory) => (
              <NavLink
                key={subcategory.web_id}
                to={`/catalog/${subcategory.web_id}`}
                className={`btn text-start category-menu-item ${isSelected(subcategory) ? 'text-main' : ''}`}
                onClick={() => {resetSearch();}}
              >
                {subcategory.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="col-md-3">
      <div className="bg-white ps-2 category-menu pe-2">
        <div className="offcanvas-header ps-xl-3 pt-0 pb-md-0 pb-1">
          <p className="h4 fw-bolder mb-0" id="drawerLabel">Categorías</p>
          <button
            type="button"
            className="btn-close d-md-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="ps-3">
          <Link to='/' onClick={hideMenu}>
            <small>Menú principal</small>
          </Link>
        </div>
        <div className="category-menu-items">
          <div className="d-flex flex-column pt-4 pb-4">
            {renderCategories()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryMenu;
