import {
  GET_STRIPE_PAYMENT_SECRET,
  GET_ORDER_FOR_CHECKOUT,
  START_SAVING_ORDER_REQUEST,
  END_SAVING_ORDER_REQUEST,
  SET_ORDER_SAVING_ERROR
} from '../actions/types';

const initialState = {
  client_secret: '',
  orderForCheckout: {},
  savingOrder: false,
  orderErrorMessage: ''
};

const orderReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_STRIPE_PAYMENT_SECRET: {
      return {
        ...state,
        client_secret: payload
      };
    }
    case GET_ORDER_FOR_CHECKOUT: {
      return {
        ...state,
        orderForCheckout: action.payload
      };
    }
    case START_SAVING_ORDER_REQUEST:
      return {
        ...state,
        savingOrder: true
      }
    case END_SAVING_ORDER_REQUEST:
      return {
        ...state,
        savingOrder: false
      }
    case SET_ORDER_SAVING_ERROR:
      return {
        ...state,
        orderErrorMessage: payload.orderErrorMessage
      }
    default:
      return state;
  }
};

export default orderReducer;
