// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import cartReducer from './cart';
import retailerReducer from './retailer';
import catalogReducer from './catalog';
import orderReducer from './order';
import customFieldReducer from './customField';
import shippingCostReducer from './shippingCost';

export default combineReducers({
  cartReducer,
  retailerReducer,
  catalogReducer,
  orderReducer,
  customFieldReducer,
  shippingCostReducer
});
