import React, { useEffect, useState } from 'react';
import TrashIcon from 'images/Trash-2-outline.svg';
import AddIcon from 'images/Plus-outline.svg';
import RemoveIcon from 'images/Minus-outline.svg';

const CatalogProductQuantity = ({ quantity, onChangeQuantity }) => {
  const [currentQuantity, setCurrentQuantity] = useState(quantity.toString());

  useEffect(() => {
    if (quantity !== currentQuantity) {
      setCurrentQuantity(quantity.toString());
    }
  }, [quantity]);

  const onPressMinus = () => {
    onChangeQuantity(quantity - 1);
  };

  const onPressPlus = () => {
    onChangeQuantity(quantity + 1);
  };

  const onChangeQuantityInput = ({ target }) => {
    if (target.value === '') {
      setCurrentQuantity('');
    } else if (/^[0-9]*$/.test(target.value)) {
      onChangeQuantity(parseInt(target.value, 10));
    }
  };

  const onBlur = ({ target }) => {
    if (target.value === '') {
      onChangeQuantity(0);
    }
  };

  return (
    <div className="catalog-product-quantity d-flex me-md-1 me-2">
      {quantity > 0 && (
        <button type="button" className="btn pe-0" onClick={onPressMinus}>
          <img src={quantity > 1 ? RemoveIcon : TrashIcon} alt="" />
        </button>
      )}
      <input
        type="text"
        value={currentQuantity}
        onChange={onChangeQuantityInput}
        onBlur={onBlur}
        className="bg-transparent border-0 text-center"
      />
      <button type="button" className="btn ps-0" onClick={onPressPlus}>
        <img src={AddIcon} alt="" />
      </button>
    </div>
  );
};

export default CatalogProductQuantity;
