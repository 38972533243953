import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

const PaymentFields = forwardRef(({ isFormValid }, paymentRef) => {
  const {
    paymentMethod
  } = useSelector((reduxState) => reduxState.cartReducer);
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const domain = () => {
    // eslint-disable-next-line no-undef
    switch (ENV.ENVIRONMENT) {
      case 'development':
        // eslint-disable-next-line no-undef
        return ENV.DOMAIN;
      case 'staging':
        return 'mercately.online';
      default:
        return 'mercately.shop';
    }
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();

    if (!stripe || !elements || !isFormValid()) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: buildReturnUrl()
      }
    });

    if (error) {
      setErrorMessage(error.message);
      setSending(false);
    } else {
      setSending(true);
    }
  };

  const buildReturnUrl = () => {
    if (retailer.domain) return `https://${domain()}/order/confirmation`;

    return `https://${retailer.catalog_slug}.${domain()}/order/confirmation`;
  };

  useImperativeHandle(paymentRef, () => ({ handleSubmit }));

  return (
    <form onSubmit={handleSubmit} className={paymentMethod === 'card' ? '' : 'd-none'}>
      <PaymentElement />
      {errorMessage && <div className="text-danger text-center">{errorMessage}</div>}
      <button
        type="submit"
        className="btn btn-order-by-whatsapp text-white d-flex justify-content-center align-items-center w-100 mt-3 d-none d-md-block"
        disabled={sending || !stripe}
      >
        {sending ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        ) : (
          'Ordenar'
        )}
      </button>
    </form>
  );
});

export default PaymentFields;
