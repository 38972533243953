import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, forEach } from "lodash";
import { v4 as uuidv4 } from 'uuid';

import {
  EMPTY_CART_AND_REMOVED_LINES,
  START_SAVING_ORDER_REQUEST,
  END_SAVING_ORDER_REQUEST,
  SET_ORDER_SAVING_ERROR,
  SET_ORDER_SAVED,
  SET_SERVER_ERROR
} from '../../../actions/types';
import httpService from "../../../services/httpService";

import { formatOrderDataToSave } from "../../../utils/util";

const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

const MercadoPagoCard = forwardRef((
  { total, retailer, isFormValid },
  paymentMercadoPagoRef
 ) => {
  const mp = new MercadoPago(`${retailer.mp_public_key}`, {
    locale: 'es-AR'
  });

  const dispatch = useDispatch();

  const {
    orderLines,
    name,
    lastname,
    email,
    dialCode,
    phone,
    documentType,
    documentId,
    address,
    description,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    country_id,
    paymentMethod,
    creditCardNumber,
    deliveryMethod,
    delivery,
    order = {},
    orderCustomFields = [],
    orderLinesRemoved = [],
    shipping_value,
    shipping_cost_id
  } = useSelector((reduxState) => reduxState.cartReducer);

  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cardPaymentBrick, setCardPaymentBrick] = useState(null);

  const renderCardPaymentBrick = async (bricksBuilder) => {
    const settings = {
      initialization: {
        amount: total, // monto a ser pagado
        payer: {
          email: "",
        },
      },
      customization: {
        visual: {
          hideFormTitle: true,
          hidePaymentButton: true,
          style: {
            theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
            baseColor: "#21974E"
          },
          texts: {
            formSubmit: "Ordenar"
          }
        },
        paymentMethods: {
          creditCard: 'all',
          debitCard: 'all',
        }
      },
      callbacks: {
        onReady: (data) => {
          console.log(data)
        },
        onError: (err) => {
          console.log(err)
          setErrorMessage(err.message);
        },
      },
    };
    const cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
    setCardPaymentBrick(cardPaymentBrickController);
  };

  useEffect(() => {
    const bricksBuilder = mp.bricks();

    renderCardPaymentBrick(bricksBuilder);

    return () => {
      if (cardPaymentBrick) cardPaymentBrick.unmount();
    };
  }, []);

  const processPayment = () => {
    if (!isFormValid()) {
      return;
    }

    setErrorMessage(null);
    setSending(true);

    cardPaymentBrick.getFormData()
    .then((cardFormData) => {
      //  callback llamado cuando el usuario haga clic en el botón enviar los datos
      //  ejemplo de envío de los datos recolectados por el Brick a su servidor
      if (!cardFormData) {
        setSending(false);
        return;
      }

      const formData = formatRequestData(cardFormData);

      return new Promise((resolve, reject) => {
        let endPoint = `/orders`;
        let method = 'POST';

        if (order && order.web_id) {
          endPoint = `${endPoint}/${order.web_id}`;
          method = 'PUT';
        }

        dispatch({ type: START_SAVING_ORDER_REQUEST });
        dispatch({ type: SET_ORDER_SAVING_ERROR, payload: {orderErrorMessage: ''} });

        httpService.save(endPoint, formData, csrfToken, method).then((res) => {
          setSending(false);
          dispatch({ type: EMPTY_CART_AND_REMOVED_LINES });
          dispatch({ type: END_SAVING_ORDER_REQUEST });
          dispatch({ type: SET_ORDER_SAVED, payload: res});
          renderPage(res);
          resolve();
        }).catch((error) => {
          dispatch({ type: END_SAVING_ORDER_REQUEST });
          dispatch({ type: SET_ORDER_SAVING_ERROR, payload: {orderErrorMessage: error.errors} });
          dispatch({ type: SET_SERVER_ERROR, payload: error});
          setSending(false);
          setTimeout(() => {
            window.location.href = `/order/error`;
          }, 300);
          reject();
        });
      });
    })
    .catch((error) => {
      console.log(error, 'tokenizar la tarjeta');
      setSending(false);
    });
  };

  const formatRequestData = (cardFormData) => {

    const formData = formatOrderDataToSave(
      {
        orderLines,
        name,
        lastname,
        email,
        dialCode,
        phone,
        documentType,
        documentId,
        address,
        description,
        city,
        state,
        zipCode,
        latitude,
        longitude,
        country_id,
        deliveryMethod,
        orderCustomFields,
        orderLinesRemoved,
        shipping_value,
        shipping_cost_id
      }
    );

    formData.mp_card_form = addExtraCardData(cardFormData);

    return formData;
  }

  const addExtraCardData = (cardFormData) => {
    const additionalInfo = { items: [], payer: {}};

    forEach(orderLines, item => {
      additionalInfo.items.push({
        id: item.web_id || `product-item-${item.id}`,
        title: item.title,
        description: item.product?.description || `Producto ${item.title}`,
        category_id: item.category_name || 'electronics',
        quantity: item.quantity,
        unit_price: Number(item.unit_price)
      });
    });

    additionalInfo.payer = {
      first_name: name,
      last_name: lastname,
      address: {}
    }

    if (dialCode && phone) {
      additionalInfo.payer = {
        ...additionalInfo.payer,
        phone: {
          area_code: dialCode.dialCode.substring(1),
          number: phone
        }
      }
    }

    if (address || zipCode) {
      additionalInfo.payer.address = {
        ...additionalInfo.payer.address,
        zip_code: zipCode,
        street_name: address
      }
      additionalInfo.shipments = {
        receiver_address: {
          zip_code: zipCode,
          street_name: address
        }
      }
    }

    cardFormData.additional_info = additionalInfo;

    cardFormData.payer = {
      ...cardFormData.payer,
      first_name: name,
      last_name: lastname
    }

    if (documentType && documentId) {
      cardFormData.payer = {
        ...cardFormData.payer,
        identification: {
          type: documentType.value,
          number: documentId
        }
      }
    }

    cardFormData.description = 'Pago por compra de productos';
    cardFormData.external_reference = `MP-${uuidv4()}`;
    cardFormData.notification_url = `${window.location.protocol}//${window.location.hostname}/mercadopago/webhook`;
    cardFormData.statement_descriptor = "MERCADOPAGO";

    return cardFormData;
  }

  const renderPage = (res) => {
    const status = res.payment_transaction?.status;
    let page;

    switch (status) {
      case 'success':
      case 'pending':
        page = 'confirmation';
        break;
      default:
        page = 'error';
    }

    setTimeout(() => {
      window.location.href = `/order/${page}`;
    }, 300);
  }

  useImperativeHandle(paymentMercadoPagoRef, () => ({ processPayment }));

  return(
    <div className={paymentMethod === 'card' ? '' : 'd-none'}>
      <div id="cardPaymentBrick_container"></div>
      {errorMessage && <div className="text-danger text-center">{errorMessage}</div>}
      <button
        type="button"
        className="btn btn-order-by-whatsapp text-white d-flex justify-content-center align-items-center w-100 mt-3 d-none d-md-block"
        onClick={processPayment}
        disabled={sending}
      >
        {sending ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        ) : (
          'Ordenar'
        )}
      </button>
    </div>
  )
});

export default MercadoPagoCard;