import {
  ADD_LINE,
  CHANGE_USER_FIELD,
  EMPTY_CART,
  EMPTY_CART_AND_REMOVED_LINES,
  REMOVE_LINE,
  UPDATE_LINE
} from '../actions/types';

const injectShippingCostMiddleware = (store) => (next) => (action) => {
  if (typeof action === 'function') {
    return action(store.dispatch, store.getState);
  }

  const state = store.getState();
  const fixedCostShippingValue = state.retailerReducer?.retailer?.shipping_cost?.fixed_cost_shipping_value;

  const actionTypes = [
    ADD_LINE,
    UPDATE_LINE,
    REMOVE_LINE,
    EMPTY_CART,
    CHANGE_USER_FIELD,
    EMPTY_CART_AND_REMOVED_LINES
  ];

  if (actionTypes.includes(action.type)) {
    const newPayload = {
      originalPayload: action.payload,
      fixedCostShippingValue
    };
    const newAction = { ...action, payload: newPayload };
    return next(newAction);
  }

  return next(action);
};

export default injectShippingCostMiddleware;
