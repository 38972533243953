import Big from 'big.js';
import {
  filter,
  find,
  forEach,
  head,
  sumBy,
  without
} from "lodash";
import { pluck } from '../utils/util';

export const unifySimilarProducts = (orderItems) => {
  const unifyOrderItems = [];

  let newOrderItems = [...orderItems];
  let filteredItems = [];
  let itemWithId = null;

  while (newOrderItems.length > 0) {
    const orderItem = newOrderItems[0];

    filteredItems = filterItems(orderItems, orderItem);
    itemWithId = find(filteredItems, (o) => o.id != null || o.id != undefined);

    const firstItem = head(filteredItems);
    const quantity = sumQuantities(filteredItems);
    const subtotal = Number(Big(Number(quantity)).times(Big(Number(firstItem.unit_price))))

    const newItem = {
      ...firstItem,
      quantity,
      subtotal,
      taxes: Number(Big(Number(subtotal)).times(Number(firstItem.custom_tax)).div(100).round(2))
    };

    if (itemWithId) {
      newItem.id = itemWithId.id;
    }

    unifyOrderItems.push(newItem);

    newOrderItems = removeAddedProduct(newOrderItems, filteredItems);
  }

  return { unifyOrderItems };
};

const filterItems = (orderItems, orderItem) => {
  return filter(orderItems, (item) => {
    return (
      item.web_id == orderItem.web_id &&
      Number(item.unit_price) == Number(orderItem.unit_price) &&
      Number(item.custom_tax) == Number(orderItem.custom_tax) &&
      hasSameVariants(orderItem.variants, item.variants)
    );
  });
};

const hasSameVariants = (array1, array2) => {
  if (array1.length != array2.length) return false;

  let ids1 = pluck(array1, 'variant_option_id');
  let ids2 = pluck(array2, 'variant_option_id');

  return ids1.sort().every((element, index) => element === ids2.sort()[index]);
}

const sumQuantities = (filteredItems) => {
  return sumBy(filteredItems, (item) => { return Number(item.quantity); })
};

const removeAddedProduct = (newOrderItems, filteredItems = []) => {
  forEach(filteredItems, (item) => {
    newOrderItems = without(newOrderItems, item);
  });

  return newOrderItems;
}