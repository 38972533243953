import { GET_RETAILER_INFO } from '../actions/types';

const initialState = {
  retailer: {}
};

const retailerReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_RETAILER_INFO: {
      return {
        ...state,
        retailer: payload.retailer
      };
    }
    default:
      return state;
  }
};

export default retailerReducer;
