import { getRegionalConfig } from '../config';

export const formatMoney = (number = 0) => {
  const { number_of_decimals = 2, thousands_separator = ',', decimal_separator = '.' } = getRegionalConfig();

  const minimumFractionDigits = Number(number_of_decimals);

  if (minimumFractionDigits === 0) {
    number = roundUpInteger(Number(number), number_of_decimals);
  };

  let formattedNumber = number.toString();

  const parts = formattedNumber.split('.');
  let intPart = parts[0];
  let decimalPart = minimumFractionDigits > 0 ? (parts.length > 1 ? parts[1] : '') : '';

  intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, `${thousands_separator}`);

  if (decimalPart.length < minimumFractionDigits) {
    decimalPart = decimalPart.padEnd(minimumFractionDigits, '0');
  }

  formattedNumber = intPart + (decimalPart.length > 0 ? `${decimal_separator}` + decimalPart : '');

  return formattedNumber;
};

export const roundUpInteger = (number, numberOfDecimals = 2) => {

  if (Number(numberOfDecimals) > 0) return number;

  number = Number(number);

  let integerPart = Math.floor(number);
  const decimalPart = number - integerPart;

  if (decimalPart >= 0.5) {
    integerPart++;
  }

  return integerPart;
};