import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import PrevIcon from 'images/nav-left.svg';
import NextIcon from 'images/nav-right.svg';
import { NavLink } from 'react-router-dom';
import CatalogProductItem from '../../CatalogProductItem';
import { getProductsBySubcategory } from '../../../../actions';

const CategoryProductCarousel = ({
  category,
  isSmallDevice,
  onSelectProduct
}) => {
  const dispatch = useDispatch();
  const [productsGrouped, setProductsGrouped] = useState([]);
  const [page, setPage] = useState(3);
  const currentPage = useRef(0);
  const lastPage = useRef(0);

  const getProductsGrouped = () => {
    const array = cloneDeep(category.products);
    const result = [];
    const numberOfGroup = isSmallDevice ? 2 : 3;
    while (array.length > 0) {
      const groupByNumber = array.length >= numberOfGroup ? numberOfGroup : array.length;
      result.push(array.splice(0, groupByNumber));
    }
    return result;
  };

  const carouselId = `productsCarousel-${category.web_id}`;

  useEffect(() => {
    setProductsGrouped(getProductsGrouped());
  }, [category.products, isSmallDevice]);

  useEffect(() => {
    const prevBtn = document.getElementById(`${carouselId}-prev-btn`);
    const nextBtn = document.getElementById(`${carouselId}-next-btn`);
    if (!isSmallDevice) {
      if (currentPage.current == 0) {
        prevBtn.style.opacity = '0.4';
      } else {
        prevBtn.style.opacity = '1';
      }

      if (currentPage.current == productsGrouped.length - 1) {
        nextBtn.style.opacity = '0.4';
      }
      else {
        nextBtn.style.opacity = '1';
      }
    }

    const carouselListener = (e) => {
      if (!isSmallDevice) {
        if (e.to === 0) {
          prevBtn.style.opacity = '0.4';
        } else if (e.to === productsGrouped.length - 1) {
          nextBtn.style.opacity = '0.4';
        } else {
          prevBtn.style.opacity = '1';
          nextBtn.style.opacity = '1';
        }
      }
      if (e.to > lastPage.current) {
        lastPage.current = e.to;
      }
      currentPage.current = e.to;
    };

    const myCarousel = document.getElementById(carouselId);
    myCarousel.addEventListener('slid.bs.carousel', carouselListener);

    return () => myCarousel.removeEventListener('slid.bs.carousel', carouselListener);
  }, [productsGrouped]);

  const getMoreProducts = () => {
    if (currentPage.current == lastPage.current && lastPage.current < productsGrouped.length - 1) {
      const params = {page: page};
      dispatch(getProductsBySubcategory(category.web_id, params));
      setPage((prevValue) => prevValue + 1);
    }
  }

  return (
    <div className="category-product-carousel bg-white">
      <div className="d-flex pb-3">
        <div className="flex-grow-1 d-flex align-items-center">
          <h4 className="fw-bolder mb-0">{category.name}</h4>
          <NavLink to={`/catalog/${category.web_id}`} className="ps-3 text-main text-decoration-none">Ver más</NavLink>
        </div>
        <button
          id={`${carouselId}-prev-btn`}
          className="btn pt-0 pb-0"
          type="button"
          data-bs-target={`#${carouselId}`}
          data-bs-slide="prev"
        >
          <img src={PrevIcon} alt="" />
        </button>
        <button
          id={`${carouselId}-next-btn`}
          className="btn pt-0 pb-0"
          type="button"
          data-bs-target={`#${carouselId}`}
          data-bs-slide="next"
          onClick={getMoreProducts}
        >
          <img src={NextIcon} alt="" />
        </button>
      </div>
      <div
        id={carouselId}
        className="carousel slide mt-3"
        data-bs-ride="carousel"
        data-bs-interval="false"
        data-bs-wrap="false"
      >
        <div className="carousel-inner">
          {productsGrouped.map((products, key) => (
            <div className={`carousel-item ${key === 0 ? 'active' : ''}`} key={key.toString()}>
              <div className="row">
                {products.map((product) => (
                  <CatalogProductItem
                    product={product}
                    key={product.web_id}
                    onSelectProduct={onSelectProduct}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryProductCarousel;
