import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPaidOrder as createOrderAction,
  updatePaidOrder as updateOrderAction
} from '../../actions';
import { forEach, isEmpty } from 'lodash';
import StatusMessage from './StatusMessage';
import BottomSheetModal from '../Checkout/BottomSheetModal';
import { EMPTY_CART_AND_REMOVED_LINES } from '../../actions/types';

import { formatOrderDataToSave } from '../../utils/util';

const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

const OrderConfirmation = () => {
  const {
    orderLines,
    name,
    lastname,
    email,
    dialCode,
    phone,
    documentType,
    documentId,
    address,
    description,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    country_id,
    deliveryMethod,
    order = {},
    orderCustomFields = [],
    orderSaved = {},
    orderLinesRemoved,
    shipping_value,
    shipping_cost_id
  } = useSelector((reduxState) => reduxState.cartReducer);

  const dispatch = useDispatch();

  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paymentIntent = urlParams.get('payment_intent');
    const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');

    if (!paymentIntent || !paymentIntentClientSecret) {
      dispatch({ type: EMPTY_CART_AND_REMOVED_LINES });
      return;
    }

    setPaymentStatus('success');

    const formData = formatOrderDataToSave(
      {
        orderLines,
        name,
        lastname,
        email,
        dialCode,
        phone,
        documentType,
        documentId,
        address,
        description,
        city,
        state,
        zipCode,
        latitude,
        longitude,
        country_id,
        deliveryMethod,
        orderCustomFields,
        orderLinesRemoved,
        shipping_value,
        shipping_cost_id
      }
    );

    formData.channel = 'whatsapp';
    formData.stripe_transaction_attributes = {
      transaction_id: paymentIntent
    }

    if (order.web_id) {
      formData.web_id = order.web_id;
      dispatch(updateOrderAction(formData, csrfToken));
    } else {
      dispatch(createOrderAction(formData, csrfToken));
    }
  }, []);

  useEffect(() => {
    if (!orderSaved || isEmpty(orderSaved)) return;

    if (!orderSaved.payment_transaction) {
      setPaymentStatus('created_order');
      return;
    }

    setPaymentStatus(orderSaved.payment_transaction.status);
  }, [orderSaved]);

  return (window.innerWidth < 760 ? (
    <BottomSheetModal
      onDismiss={() => { window.location.href = '/'; }}
      className="order-confirmation-modal text-center"
      name="orderStatus"
      visible
    >
      <StatusMessage paymentStatus={paymentStatus} />
    </BottomSheetModal>
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="order-confirmation text-center d-flex flex-column justify-content-center align-items-center">
        <StatusMessage paymentStatus={paymentStatus} />
      </div>
    </div>
  ));
};

export default OrderConfirmation;
