// eslint-disable-next-line import/no-extraneous-dependencies
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from '../reducers/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import injectShippingCostMiddleware from '../middlewares/injectShippingCostMiddleware';

const persistConfig = {
  key: 'shops',
  storage,
  whitelist: ['cartReducer', 'retailerReducer']
};

const logger = createLogger({
  level: 'info',
  collapsed: false,
  logger: console,
  predicate: (getState, action) => true
});

let middlewares = [thunkMiddleware, injectShippingCostMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middlewares = [...middlewares, logger];
}
const pReducer = persistReducer(persistConfig, reducers);

const middleware = applyMiddleware(...middlewares);
export default function configureStore() {
  const store = createStore(pReducer, middleware);
  const persistor = persistStore(store);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('../reducers');
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }
  return { persistor, store };
}
