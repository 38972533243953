import { cloneDeep, find, forEach, isEmpty, without } from 'lodash';
import { unifySimilarProducts } from '../services/orderService';

import {
  ADD_LINE,
  ADD_ORDER_LINE,
  CHANGE_USER_FIELD,
  EMPTY_CART,
  GET_ORDER,
  REMOVE_LINE,
  UPDATE_LINE,
  EMPTY_ORDER_CART,
  SET_ORDER_FIELDS,
  EMPTY_ORDER_CUSTOM_FIELDS,
  ADD_ORDER_CUSTOM_FIELD,
  CHANGE_ORDER_CUSTOM_FIELD,
  ADD_ALL_ORDER_CUSTOM_FIELD,
  SET_ORDER_SAVED,
  SET_SERVER_ERROR,
  ADD_REMOVED_LINE,
  EMPTY_CART_AND_REMOVED_LINES
} from '../actions/types';
import { computeTotal } from '../utils/cart';

const initialState = {
  name: "",
  lastname: "",
  email: "",
  dialCode: "",
  phone: "",
  documentType: "",
  documentId: "",
  address: "",
  description: "",
  city: "",
  state: "",
  zipCode: "",
  latitude: "",
  longitude: "",
  country_id: "",
  paymentMethod: "",
  creditCardNumber: "",
  cardMonth: "",
  cardYear: "",
  CVVCard: "",
  store: null,
  deliveryMethod: "",
  shipping_value: 0,
  shipping_cost_id: '',
  deliveryPoint: "",
  order: {},
  orderLines: [],
  discount: 0,
  delivery: 0,
  subtotal: 0,
  totalDiscount: 0,
  totalTaxes: 0,
  total: 0,
  orderCustomFields: [],
  orderSaved: {},
  orderLinesRemoved: [],
};

const parsePayload = (action) => {
  const { fixedCostShippingValue } = action?.payload || {};
  let payload = action?.payload?.originalPayload;

  if (isEmpty(payload)) {
    payload = action?.payload || {};
  }

  return {
    payload,
    fixedCostShippingValue
  };
};

const cartReducer = (state = initialState, action) => {
  const { type } = action;
  const { payload } = parsePayload(action);

  switch (type) {
    case ADD_LINE: {
      const orderLines = [...state.orderLines];
      orderLines.push(payload);

      const { unifyOrderItems } = unifySimilarProducts(orderLines);
      return {
        ...state,
        orderLines: unifyOrderItems,
        ...computeTotal(unifyOrderItems, state.shipping_value)
      };
    }
    case UPDATE_LINE: {
      const orderLines = [...payload];

      return {
        ...state,
        orderLines,
        ...computeTotal(orderLines, state.shipping_value)
      };
    }
    case REMOVE_LINE: {
      let orderLines = [...state.orderLines];
      let orderLinesRemoved = [];
      const lineIndex = orderLines.lastIndexOf(payload);
      if (lineIndex !== -1) {
        if (payload.id) {
          orderLinesRemoved = [...state.orderLinesRemoved];
          orderLinesRemoved.push({ ...payload, _destroy: true });
        }

        orderLines = without(orderLines, payload);
      }

      return {
        ...state,
        orderLines,
        orderLinesRemoved,
        ...computeTotal(orderLines, state.shipping_value)
      };
    }
    case EMPTY_CART: {
      const orderLinesRemoved = [...state.orderLinesRemoved];

      forEach(state.orderLines, (line) => {
        if (line.id) {
          orderLinesRemoved.push({ ...line, _destroy: true });
        }
      });

      return {
        ...state,
        orderLines: [],
        orderLinesRemoved,
        delivery: 0,
        ...computeTotal([], state.shipping_value)
      };
    }
    case GET_ORDER: {
      return {
        ...state,
        order: action.payload
      };
    }
    case ADD_ALL_ORDER_CUSTOM_FIELD: {
      return {
        ...state,
        orderCustomFields: payload
      };
    }
    case EMPTY_ORDER_CUSTOM_FIELDS: {
      return {
        ...state,
        orderCustomFields: []
      };
    }
    case ADD_ORDER_CUSTOM_FIELD: {
      const { orderCustomFields = [] } = state;
      orderCustomFields.push(payload);
      return {
        ...state,
        orderCustomFields
      };
    }
    case CHANGE_ORDER_CUSTOM_FIELD: {
      const clonedOrderCustomFields = cloneDeep(state.orderCustomFields);

      const fieldIn = find(clonedOrderCustomFields, { name: payload.field });
      if (fieldIn) {
        fieldIn.answer = payload.data;
        fieldIn.error = '';
        if (fieldIn.mandatory_in_shops && !fieldIn.answer) {
          fieldIn.error = '* Requerido';
        }
      }

      return {
        ...state,
        orderCustomFields: clonedOrderCustomFields
      };
    }
    case ADD_ORDER_LINE: {
      const orderLines = [...state.orderLines];
      orderLines.push(payload);
      return {
        ...state,
        orderLines
      };
    }
    case EMPTY_ORDER_CART: {
      return {
        ...state,
        orderLines: [],
        orderLinesRemoved: []
      };
    }
    case CHANGE_USER_FIELD: {
      let dataToAdd = {
        [payload.field]: payload.data
      };

      if (payload.field === 'shipping_value') {
        dataToAdd = {
          ...dataToAdd,
          ...computeTotal(state.orderLines, payload.data)
        };
      }
      return {
        ...state,
        ...dataToAdd
      };
    }
    case SET_ORDER_FIELDS: {
      return {
        ...state,
        ...payload
      };
    }
    case SET_ORDER_SAVED:
      return {
        ...state,
        orderSaved: payload
      };
    case SET_SERVER_ERROR:
      return {
        ...state,
        orderSaved: payload
      };
    case ADD_REMOVED_LINE: {
      const orderLinesRemoved = [...state.orderLinesRemoved];
      orderLinesRemoved.push(payload);

      return {
        ...state,
        orderLinesRemoved
      };
    }
    case EMPTY_CART_AND_REMOVED_LINES:
      return {
        ...state,
        orderLines: [],
        orderLinesRemoved: [],
        order: {},
        delivery: 0,
        ...computeTotal([], state.shipping_value)
      };
    default:
      return state;
  }
};

export default cartReducer;
