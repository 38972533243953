import Big from 'big.js';

import {
  ADD_LINE,
  CHANGE_USER_FIELD,
  EMPTY_CART,
  REMOVE_LINE,
  UPDATE_LINE,
  GET_ORDER,
  ADD_ORDER_LINE,
  EMPTY_ORDER_CART,
  SET_ORDER_FIELDS,
  EMPTY_ORDER_CUSTOM_FIELDS,
  ADD_ORDER_CUSTOM_FIELD,
  CHANGE_ORDER_CUSTOM_FIELD,
  ADD_ALL_ORDER_CUSTOM_FIELD
} from './types';

import {
  getOrder as getOrderService
} from '../services';

export const addLine = (product, quantity = 1, taxAmount = 0, variants = []) => {
  const newLine = {
    id: null,
    web_id: product.web_id,
    product,
    subtotal: Number(Big(Number(quantity)).times(Big(Number(product.price)))),
    discount: 0,
    quantity,
    title: product.title,
    unit_price: product.price,
    custom_tax: taxAmount,
    category_name: product.category_name || 'electronics',
    variants: variants,
    _destroy: false
  };
  let taxes = Big(Number(newLine.subtotal)).times(Number(taxAmount)).div(100);
  newLine.taxes = Number(taxes.round(2));

  return (dispatch) => {
    dispatch({ type: ADD_LINE, payload: newLine });
  };
};

export const updateLine = (lines) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_LINE, payload: lines });
  };
};

export const removeLine = (line) => (dispatch) => {
  dispatch({ type: REMOVE_LINE, payload: line });
};

export const emptyCart = () => (dispatch) => {
  dispatch({ type: EMPTY_CART });
};

export const emptyOrderCart = () => (dispatch) => {
  dispatch({ type: EMPTY_ORDER_CART });
};

export const getOrder = (order) => (dispatch) => {
  if (order) {
    getOrderService(order)
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: GET_ORDER, payload: data });
      });
  } else {
    dispatch({ type: GET_ORDER, payload: {} });
  }
};

export const emptyOrderCustomFields = () => (dispatch) => {
  dispatch({ type: EMPTY_ORDER_CUSTOM_FIELDS });
};

export const addOrderCustomField = (customField) => {
  return (dispatch) => {
    dispatch({ type: ADD_ORDER_CUSTOM_FIELD, payload: customField });
  };
};

export const addOrderLine = (orderItem, tax = 0) => {
  const { product, quantity, subtotal, id, title, unit_price, custom_tax, variants } = orderItem;
  const newLine = {
    id,
    title,
    unit_price,
    custom_tax,
    web_id: product.web_id || id,
    product,
    subtotal,
    discount: 0,
    quantity,
    category_name: orderItem.category_name || 'electronics',
    variants,
    _destroy: false
  };
  let taxes = Big(Number(subtotal)).times(Number(custom_tax)).div(100);
  newLine.taxes = Number(taxes.round(2));

  return (dispatch) => {
    dispatch({ type: ADD_ORDER_LINE, payload: newLine });
  };
};

export const changeUserField = (field, data) => (dispatch) => {
  dispatch({ type: CHANGE_USER_FIELD, payload: { field, data } });
};

export const setOrderFields = (order) => (dispatch) => {
  dispatch({ type: SET_ORDER_FIELDS, payload: order });
};

export const changeOrderCustomField = (field, data) => (dispatch) => {
  dispatch({ type: CHANGE_ORDER_CUSTOM_FIELD, payload: { field, data } });
}

export const addAllOrderCustomField = (data) => (dispatch) => {
  dispatch({ type: ADD_ALL_ORDER_CUSTOM_FIELD, payload: data });
}