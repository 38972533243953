import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import CategoryProductCarousel from './CategoryProductCarousel';
import CatalogProducts from '../CatalogProducts';
import ProductModal from '../CatalogProducts/ProductModal';
import { getProduct } from '../../../actions';

const CategoryProducts = ({
  isSmallDevice,
  currentCategory,
  categories,
  handleMoreItems,
  displayMoreItemsButton,
  submitted
}) => {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState({});
  const { product } = useSelector((reduxState) => reduxState.catalogReducer);

  useEffect(() => {
    if (!isEmpty(selectedProduct)) {
      dispatch(getProduct(selectedProduct.web_id));
    } else {
      dispatch(getProduct(''));
    }
  }, [selectedProduct]);

  return (
    <div>
      {!currentCategory.web_id ? (
        <>
          {categories.map((category, key) => (
            <CategoryProductCarousel
              key={key.toString()}
              category={category}
              isSmallDevice={isSmallDevice}
              onSelectProduct={setSelectedProduct}
            />
          ))}
          { (displayMoreItemsButton && categories && categories.length > 0) && (
            <div className="col-md-12 text-center s-mt-20 s-mb-20">
              <button onClick={handleMoreItems} className="btn btn-main view-more-button text-white" disabled={submitted}>
                {submitted && <FontAwesomeIcon icon={faSpinner} className="fa-spin m-mr-8" />}
                Ver más
              </button>
            </div>
          )}
        </>
      )
        : (
          <CatalogProducts
            category={currentCategory}
            onSelectProduct={setSelectedProduct}
          />
        )}
      <ProductModal
        product={product}
        onDismiss={() => setSelectedProduct({})}
        isSmallDevice={isSmallDevice}
      />
    </div>
  );
};

export default CategoryProducts;
