import Big from 'big.js';

export const getDeliveryAmount = (retailer) => {
  const { shipping_cost_method, shipping_costs } = retailer;

  if (!Array.isArray(shipping_costs) || shipping_costs.length === 0) {
    return 0;
  }

  const shippingCosts = shipping_costs.filter(
    (item) => item.shipping_type === shipping_cost_method
  );

  if (shipping_cost_method === 'fixed_cost' && shippingCosts.length > 0) {
    return shippingCosts[0].cost;
  }
  return 0;
};

export const computeTotal = (
  lines = [],
  shipping_cost = 0
) => {
  let total = Big(0);
  let subtotal = Big(0);
  let totalTaxes = Big(0);
  let totalDiscount = Big(0);

  lines.forEach((line) => {
    if (!line._destroy) {
      subtotal = subtotal.plus(Number(line.subtotal));
      totalTaxes = totalTaxes.plus(Number(line.taxes));
      totalDiscount = totalDiscount.plus(Number(line.discount));
    }
  });

  total = subtotal.plus(totalTaxes).plus(Number(shipping_cost)).minus(totalDiscount);

  return {
    total: total.toNumber(),
    subtotal: subtotal.toNumber(),
    totalTaxes: totalTaxes.toNumber(),
    totalDiscount: totalDiscount.toNumber()
  };
};
