import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import BottomSheetModal from '../BottomSheetModal';

const BottomSheetModalOptions = ({
  title,
  name,
  value = {},
  options,
  onSelect,
  keyField = 'value',
  renderLabel,
  placeholder,
  setOpenedMobilModalOptions = () => {}
}) => {
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const [showModal, setShowModal] = useState(false);

  const onPressSelectOption = (option) => {
    onSelect(option);
    setShowModal(false);
    setOpenedMobilModalOptions(false);
  };

  const renderOptions = () => (
    options.map((option, i) => {
      const isSelected = value[keyField] === option[keyField];
      return (
        <button
          key={i.toString()}
          type="button"
          className="btn w-100 d-flex align-items-center p-2 ps-0"
          onClick={() => onPressSelectOption(option)}
        >
          {renderLabel
            ? renderLabel(option, isSelected)
            : (
              <span className={`flex-grow-1 text-start ${isSelected ? 'text-main' : ''}`}>
                {option.label}
              </span>
            )}
          {isSelected && (
            <FontAwesomeIcon icon={faCheck} color={retailer.shop_main_color} />
          )}
        </button>
      );
    })
  );

  const renderLabelOrPlaceholder = () => {
    if (value[keyField]) {
      return (
        renderLabel
          ? renderLabel(value, false)
          : <span className="flex-grow-1 text-start">{value.label}</span>
      );
    }
    return <span className="flex-grow-1 text-start">{placeholder}</span>;
  };

  return (
    <div className="bottom-sheet-modal-options">
      <div className="d-flex align-items-center" onClick={() => {setShowModal(true); setOpenedMobilModalOptions(true)}}>
        {renderLabelOrPlaceholder()}
        <FontAwesomeIcon icon={faChevronDown} size="xs" />
      </div>
      {showModal && (
        <BottomSheetModal
          visible={showModal}
          onDismiss={() => {setShowModal(false); setOpenedMobilModalOptions(false);}}
          title={title}
          name={name}
        >
          <div className="pt-3 pb-3">
            {renderOptions()}
          </div>
        </BottomSheetModal>
      )}
    </div>
  );
};

export default BottomSheetModalOptions;
