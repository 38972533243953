import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import countryToCurrency from 'country-to-currency';
import numeral from 'numeral';

import { useDispatch, useSelector } from 'react-redux';
import {
  cloneDeep, filter, find, forEach, isEmpty
} from 'lodash';
import ArrowIcon from 'images/Arrow-forward-fill.svg';
import DeliveryIcon from 'images/delivery-truck 1.svg';
import StoreIcon from 'images/store 1.svg';
import ProductExample from 'images/img-holder.png';
import TinyCardIcon from 'images/TinyCreditCard.svg';
import TrashIcon from '../../../assets/images/Trash-2-outline-red.svg';
import LockIcon from '../../../assets/images/lock 1.svg';
import MiniCreditCard from '../../../assets/images/MiniCreditCard.svg';
import PoweredIcon from '../../../assets/images/powered.png';
import CheckoutSteps from './CheckoutSteps';
import InformationFields from './InformationFields';
import DeliveryFields from './DeliveryFields';
import StepFooter from './CheckoutFooter';
import BackIcon from '../../../assets/images/Arrow-ios-back-fill.svg';
import BottomSheetModal from './BottomSheetModal';
import PaymentFields from './PaymentFields';
import ConfirmationFields from './ConfirmationFields';
import OrderCustomFields from './OrderCustomFields';
import {
  changeUserField,
  removeLine,
  getStripePaymentSecret,
  createOrder as createOrderAction,
  updateOrder as updateOrderAction,
  addOrderLine,
  emptyOrderCart,
  setOrderFields,
  emptyOrderCustomFields,
  addAllOrderCustomField
} from '../../actions';
import WhatsappIcon from '../../../assets/images/logo-wa.svg';
import { validateEmail } from '../../utils/validators';
import { countriesPhonesOptions } from '../../utils/countries';
import { documentTypesOptions } from '../../utils/documentTypes';
import MercadoPagoCard from './MercadoPago/card';
import {
  GET_ORDER,
  SET_ORDER_SAVED,
  SET_SHIPPING_COSTS
} from '../../actions/types';

import { filteredLines, formatOrderDataToSave, variantsHowText } from '../../utils/util';
import httpService from '../../services/httpService';
import PayphoneButton from './payphone/PayphoneButton';
import { formatMoney } from '../../utils/number';
import MapComponent from '../Shared/MapComponent';
import { getDeliveryAmount } from '../../utils/cart';

const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

const Checkout = () => {
  const defaultOrder = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    orderLines: ''
  };
  const {
    orderLines,
    subtotal,
    total,
    totalDiscount,
    totalTaxes,
    name,
    lastname,
    email,
    dialCode,
    phone,
    documentType,
    documentId,
    address,
    description,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    country_id,
    paymentMethod,
    creditCardNumber,
    deliveryMethod,
    delivery,
    shipping_value,
    shipping_cost_id,
    order = {},
    orderCustomFields = [],
    orderLinesRemoved
  } = useSelector((reduxState) => reduxState.cartReducer);
  const paymentRef = useRef();
  const paymentMercadoPagoRef = useRef();
  const catalogoPageRef = useRef();
  const { retailer = {} } = useSelector(
    (reduxState) => reduxState.retailerReducer
  );
  const { client_secret = '', orderErrorMessage } = useSelector((reduxState) => reduxState.orderReducer);
  const { shippingCosts } = useSelector((reduxState) => reduxState.shippingCostReducer);

  const hasShippingMethod = retailer.active_store_pickup || retailer.active_send_order;
  const [currentStep, setCurrentStep] = useState(0);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(defaultOrder);
  const [currency, setCurrency] = useState('$');
  const [currencyCode, setCurrencyCode] = useState('usd');
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 760);
  const [isValidTransactionAmount, setIsValidTransactionAmount] = useState(true);
  const [orderCreatedForPayphone, setOrderCreatedForPayphone] = useState({});
  const [showMobileMapModal, setShowMobileMapModal] = useState(false);
  const [orderFromCheckout, setOrderFromCheckout] = useState({});

  const isFinalStep = currentStep === 3;
  const isWhatsappFinalStep = currentStep === 2 && paymentMethod === 'whatsapp';
  const hasMercadoPago = !!retailer.mp_public_key;
  const hasPayphone = retailer.payphone_connected;

  const dispatch = useDispatch();

  const orderParam = new URLSearchParams(window.location.search).get('order');

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    catalogoPageRef.current.click();
  };

  const createWhatsappOrder = (e) => {
    if (!isFormValid()) {
      return;
    }
    e.target.disabled = true;
    createOrder();
  };

  const onResizeWindow = () => {
    setIsSmallDevice(window.innerWidth <= 760);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    window.addEventListener('resize', onResizeWindow);

    dispatch(changeUserField('paymentMethod', ''));
    dispatch({ type: SET_ORDER_SAVED, payload: {} });
    fetchShippingConsts();

    if (orderParam) {
      fetchOrderForCheckout(orderParam);
    } else if (!order || !order.web_id) {
      fetchCustomFields();
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      window.removeEventListener('resize', onResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (retailer.currency) setCurrency(retailer.currency);
    if (
      retailer.active_send_order
      && !retailer.active_store_pickup
      && deliveryMethod == 'store_pickup'
    ) {
      emptyField('deliveryMethod');
    } else if (
      !retailer.active_send_order
      && retailer.active_store_pickup
      && deliveryMethod == 'send_order'
    ) {
      emptyField('deliveryMethod');
    }

    handleSetCurrency();
  }, [retailer.currency, retailer.currency_code]);

  useEffect(() => {
    if (retailer.stripe_id) {
      dispatch(getStripePaymentSecret(numeral(total).format('0.00')));
    }
  }, [total, retailer.stripe_id]);

  useEffect(() => {
    if (deliveryMethod && !deliveryMethod === 'send_order') {
      onChangeField("shipping_cost_id", null);
      onChangeField("shipping_value", 0);
    }
  }, [deliveryMethod]);

  const fetchShippingConsts = () => {
    const endPoint = `/shipping_costs`;
    httpService.get(endPoint).then((res) => {
      dispatch({ type: SET_SHIPPING_COSTS, payload: res.shipping_costs });
    });
  };

  const handleSetCurrency = (orderForCheckout) => {
    let newOrderCurrency;
    if (orderForCheckout) {
      newOrderCurrency = orderForCheckout.currency || retailer.currency_code;
    } else if (order) {
      newOrderCurrency = order.currency || retailer.currency_code;
    } else {
      newOrderCurrency = retailer.currency_code;
    }

    setCurrencyCode(newOrderCurrency);
  };

  const onChangeField = (field, data) => {
    dispatch(changeUserField(field, data));
  };

  const fetchOrderForCheckout = (orderWebId) => {
    const endPoint = `/orders/${orderWebId}`;
    httpService.get(endPoint).then((res) => {
      if (!res.has_payment_transaction) {
        dispatch({ type: GET_ORDER, payload: res });
        fillOrderData(res);
      } else {
        setOrderFromCheckout(res);
      }
    });
  };

  const fillOrderData = (orderForCheckout) => {
    if (
      !orderForCheckout
      || isEmpty(orderForCheckout)
      || orderForCheckout.has_payment_transaction
    ) return;

    const { customer, order_items } = orderForCheckout;

    dispatch(emptyOrderCart());
    dispatch(emptyOrderCustomFields());

    const country = countriesPhonesOptions.find(
      (e) => e.value === customer.country_id
    );
    const cart = {
      subtotal: orderForCheckout.subtotal,
      total: orderForCheckout.total,
      totalTaxes: orderForCheckout.tax_amount,
      state: orderForCheckout.state,
      city: orderForCheckout.city,
      address: orderForCheckout.address,
      zipCode: orderForCheckout.zip_code,
      shipping_value: orderForCheckout.shipping_value,
      name: customer.first_name,
      lastname: customer.last_name,
      email: customer.email,
      dialCode: country,
      shipping_cost_id: orderForCheckout.shipping_cost_id,
      shipping_cost: orderForCheckout.shipping_cost,
      phone: customer.phone?.replace(country.dialCode, ''),
      documentType: documentTypesOptions.find(
        (e) => e.value === customer.id_type
      ),
      documentId: customer.id_number
    };

    const custom_answers = filter(
      orderForCheckout.custom_answers,
      (item) => item.custom_field.show_in_catalog && item.custom_field.active
    );
    const newAllCustomFields = [];

    custom_answers.forEach((customAnswer) => {
      newAllCustomFields.push({
        id: customAnswer.id,
        custom_field_id: customAnswer.custom_field_id,
        answer: customAnswer.answer,
        name: customAnswer.custom_field.name,
        label: customAnswer.custom_field.label,
        mandatory_in_shops: customAnswer.custom_field.mandatory_in_shops,
        kind: customAnswer.custom_field.kind,
        list_options: customAnswer.custom_field.list_options,
        error: ''
      });
    });

    dispatch(addAllOrderCustomField(newAllCustomFields));

    const newOrderItems = order_items.map((item) => {
      if (item.order_item_variants && !isEmpty(item.order_item_variants)) {
        const newVariants = [];
        forEach(item.order_item_variants, (variant) => {
          const newVariant = {
            order_item_variant_id: variant.id,
            variant_id: variant.variant_id,
            variant_name: variant.variant_name,
            variant_option_id: variant.variant_option_id,
            variant_option_name: variant.variant_option_name,
            _destroy: false
          };
          newVariants.push(newVariant);
        });

        return { ...item, variants: newVariants };
      }
      return { ...item, variants: [] };
    });

    newOrderItems.forEach((orderItem) => dispatch(addOrderLine(orderItem, retailer.tax_amount)));
    dispatch(setOrderFields(cart));
    handleSetCurrency(orderForCheckout);
  };

  const fetchCustomFields = () => {
    const endPoint = '/custom_fields';
    const params = {
      'q[made_for_eq]': 0
    };

    httpService.get(endPoint, params).then((res) => {
      parseCustomFields(res.custom_fields);
    });
  };

  const parseCustomFields = (customFields) => {
    const newCustomFields = filter(
      customFields,
      (field) => field.show_in_catalog
    );
    const newAllCustomFields = [];
    let fieldIn;

    newCustomFields.forEach((customField) => {
      fieldIn = find(orderCustomFields, { custom_field_id: customField.id });
      if (fieldIn) {
        Object.assign(fieldIn, {
          kind: customField.kind,
          name: customField.name,
          label: customField.label,
          mandatory_in_shops: customField.mandatory_in_shops,
          list_options: customField.list_options,
          error: ''
        });

        const newFiled = cloneDeep(fieldIn);
        if (newFiled.id) {
          delete newFiled.id;
        }
        newAllCustomFields.push(newFiled);
      } else {
        let answer;
        switch (customField.kind) {
          case 'boolean':
            answer = false;
            break;
          default:
            answer = '';
        }
        newAllCustomFields.push({
          custom_field_id: customField.id,
          answer,
          name: customField.name,
          label: customField.label,
          mandatory_in_shops: customField.mandatory_in_shops,
          kind: customField.kind,
          list_options: customField.list_options,
          error: ''
        });
      }
    });

    dispatch(emptyOrderCustomFields());
    dispatch(addAllOrderCustomField(newAllCustomFields));
  };

  const emptyField = (field) => {
    dispatch(changeUserField(field, ''));
  };

  const renderCartLines = () => filteredLines(orderLines).map((line, index) => {
    const images = line.product?.image_urls || [];
    const image = (images.length && images[0].url) || ProductExample;
    const lineVariants = line.variants || [];

    return (
      <div key={`line-${line.web_id}-${index}`} className="d-flex cart-item">
        <div className="cart-image me-3 position-relative">
          <img src={image} alt="" />
          <span className="position-absolute bottom-0 end-0 badge rounded-pill">
            x
            {line.quantity}
          </span>
        </div>
        <div className="d-flex flex-md-row flex-grow-1">
          <div className="flex-grow-1 d-flex flex-column">
            {line.product.title || line.title}
            <span className="text-muted" style={{ fontSize: '12px' }}>
              {variantsHowText(lineVariants)}
            </span>
            <span className="checkout-price-item pt-1">
              {currency}
              {formatMoney(line.subtotal)}
              {' '}
              {currencyCode}
            </span>
          </div>
          {retailer.active && (
            <button
              type="button"
              className="btn text-danger justify-content-center align-items-center"
              onClick={() => dispatch(removeLine(line))}
            >
              <img src={TrashIcon} alt="" className="me-md-2 btn-delete-item" />
              {!isSmallDevice ? 'Eliminar' : ''}
            </button>
          )}
        </div>
      </div>
    );
  });

  const onClickBack = (e) => {
    e.preventDefault();
    if (currentStep === 0) {
      window.location.href = '/';
    } else if (
      !retailer.active_send_order
      && !retailer.active_store_pickup
      && currentStep === 2
    ) {
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const stripePromise = loadStripe(
    // eslint-disable-next-line no-undef
    ENV.STRIPE_PUBLIC,
    {
      stripeAccount: retailer.stripe_id || ''
    }
  );

  const options = {
    // pass the client secret from the previous step
    clientSecret: client_secret,
    // Fully customizable with the Appearance API
    appearance: {}
  };

  const isFormValid = (validSection = 'all') => {
    let formIsValid = true;
    const formErrors = { ...defaultOrder, mobile_address: '', mobile_shipping: '' };
    if (validSection === 'all' || validSection === 'information') {
      if (!name) {
        formIsValid = false;
        formErrors.first_name = '* Requerido';
      }

      if (!lastname) {
        formIsValid = false;
        formErrors.last_name = '* Requerido';
      }

      if (!email && !phone) {
        formIsValid = false;
        formErrors.email = '* Debe llenar email o teléfono';
        formErrors.phone = '* Debe llenar email o teléfono';
      }

      if (email && !validateEmail(email)) {
        formIsValid = false;
        formErrors.email = '* Correo inválido';
      }

      const clonedOrderCustomFields = cloneDeep(orderCustomFields);
      let isValid = true;

      forEach(clonedOrderCustomFields, (customField) => {
        if (
          customField.kind != 'boolean'
          && customField.mandatory_in_shops
          && !customField.answer
        ) {
          customField.error = '* Requerido';
          isValid = false;
        }
      });

      if (!isValid) {
        formIsValid = false;
        dispatch(emptyOrderCustomFields());
        dispatch(addAllOrderCustomField(clonedOrderCustomFields));
      }
    }

    if (validSection === 'all' || validSection === 'deliveryMethod') {
      if (retailer.active_send_order || retailer.active_store_pickup) {
        if (!deliveryMethod) {
          formIsValid = false;
          formErrors.deliveryMethod = '* Debe seleccionar un tipo de entrega';
        } else if (deliveryMethod === 'send_order') {
          const { shipping_cost_method } = retailer;
          const section = 'en detalles de envío';
          let errorFields = '';
          if (!address) {
            formIsValid = false;
            formErrors.address = '* Requerido';
            errorFields = `dirección`;
          };

          if (!city) {
            formIsValid = false;
            formErrors.city = '* Requerido';
            errorFields = `${errorFields}${errorFields ? ', ' : ''} ciudad`;
          };

          if (!state) {
            formIsValid = false;
            formErrors.state = '* Requerido';
            errorFields = `${errorFields}${errorFields ? ', ' : ''} estado o provincia`;
          };

          if (errorFields) {
            formErrors.mobile_address = `* Ingresa ${errorFields} ${section}`;
          };

          if (shipping_cost_method && shipping_cost_method === 'location_cost' &&
            !shipping_cost_id && !isEmpty(shippingCosts)) {

            formIsValid = false;
            formErrors.shipping_cost_id = '* Requerido';
            formErrors.mobile_shipping = `* Selecciona costo de envío ${section}`;
          };
        }
      }
    }

    if (validSection === 'all' && isEmpty(filteredLines(orderLines))) {
      formIsValid = false;
      formErrors.orderLines = '* Debe seleccionar al menos 1 producto';
    }

    setErrors(formErrors);

    return formIsValid;
  };

  const createOrder = () => {
    if (!isFormValid()) {
      return;
    }

    let messageToRetailer = `Hola!\nAcabo de hacer el siguiente pedido:\nNombre: ${name} ${lastname}`;
    if (documentType) {
      messageToRetailer += `\n${documentType?.label}: ${documentId}`;
    }

    messageToRetailer += '\nMétodo de entrega: ';

    if (deliveryMethod !== 'send_order') {
      messageToRetailer += 'Recoger en el negocio\n';
    } else {
      messageToRetailer += `Dirección de envío: ${address}, ${city}, ${state}, ${zipCode}\n`;
    }

    if (email) {
      messageToRetailer += `Correo: ${email}\n`;
    }

    const formData = formatOrderDataToSave({
      orderLines,
      name,
      lastname,
      email,
      dialCode,
      phone,
      documentType,
      documentId,
      address,
      description,
      city,
      state,
      zipCode,
      latitude,
      longitude,
      country_id,
      deliveryMethod,
      orderCustomFields,
      orderLinesRemoved,
      shipping_value,
      shipping_cost_id
    });

    formData.channel = 'whatsapp';

    forEach(orderLines, (item) => {
      const productTitle = item.product?.title || item.title;
      const productPrice = item.unit_price || item.product?.price || 0;

      if (!item._destroy) {
        const text = variantsHowText(item.variants || []);

        messageToRetailer += `\n✅ ${productTitle}${text} - ${item.quantity} un. - ${currency}${formatMoney(productPrice)} ${currencyCode}\n`;
      }
    });

    messageToRetailer += `
➡️ Subtotal: ${currency}${formatMoney(subtotal)} ${currencyCode}
➡️ Impuesto (${retailer.tax_amount}%): ${currency}${formatMoney(totalTaxes)} ${currencyCode}
➡️️ Envío: (${currency} ${formatMoney(shipping_value)} ${currencyCode})
➡️ Total: ${currency}${formatMoney(total)} ${currencyCode}`;

    setSubmitted(true);
    if (order && order.web_id) {
      formData.web_id = order.web_id;
      dispatch(
        updateOrderAction(formData, messageToRetailer, retailer, csrfToken)
      );
    } else {
      dispatch(
        createOrderAction(formData, messageToRetailer, retailer, csrfToken)
      );
    }
  };

  const onClickNextStep = () => {
    let segmentToValidate;
    switch (currentStep) {
      case 0:
        if (retailer.active_send_order || retailer.active_store_pickup) {
          segmentToValidate = 'information';
        } else {
          setCurrentStep(2);
          return;
        }
        break;
      case 1:
        segmentToValidate = 'deliveryMethod';
        break;
      case 2:
        segmentToValidate = 'paymentMethod';
        break;
      default:
        segmentToValidate = 'all';
    }

    if (isFormValid(segmentToValidate)) {
      if (isFinalStep || isWhatsappFinalStep) {
        if (paymentMethod === 'card') {
          if (retailer.stripe_id) {
            paymentRef.current.handleSubmit();
          } else if (hasMercadoPago) {
            paymentMercadoPagoRef.current.processPayment();
          }
        } else {
          createOrder();
        }
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const processByPayphone = () => {
    if (!isFormValid()) return;
    const formData = formatOrderDataToSave({
      orderLines,
      name,
      lastname,
      email,
      dialCode,
      phone,
      documentType,
      documentId,
      address,
      description,
      city,
      state,
      zipCode,
      latitude,
      longitude,
      country_id,
      deliveryMethod,
      shipping_value,
      shipping_cost_id,
      orderCustomFields
    });

    let endPoint = '/orders';
    let method = 'POST';

    if (order && order.web_id) {
      endPoint = `${endPoint}/${order.web_id}`;
      method = 'PUT';
    }

    httpService
      .save(endPoint, formData, csrfToken, method)
      .then((res) => {
        setOrderCreatedForPayphone(res);
        onChangeField('paymentMethod', 'card');
      })
      .catch(() => {
        alert('Error al crear la orden');
      });
  };

  const selectPaymentMethod = () => {
    if (paymentMethod == 'card') return;
    if (
      hasPayphone
      && Number(total) > Number(retailer.payphone_amounts?.min_amount || 0)
    ) {
      processByPayphone();
      return;
    }

    if (
      hasMercadoPago
      && Number(total) < Number(retailer.mp_amounts?.min_amount)
    ) {
      setIsValidTransactionAmount(false);
      return;
    }

    setIsValidTransactionAmount(true);

    onChangeField('paymentMethod', 'card');
    setShowPaymentModal(isSmallDevice);
  };

  const title = 'Resumen del pedido';

  const renderPaymentMethod = () => (
    <>
      <h3 className={`fw-bold mb-4 d-none d-md-block ${!isSmallDevice ? 'h5' : ''}`}>
        {isSmallDevice ? 'Método de pago' : 'Elige el método de pago'}
      </h3>
      {!isValidTransactionAmount && (
        <div className="col-md-12 mb-3">
          <span className="text-danger">
            Monto de transacción debe ser mayor a
            {' '}
            {retailer.mp_amounts?.min_amount}
            {' '}
            {countryToCurrency[retailer.mp_site]}
          </span>
        </div>
      )}
      <div className="mb-3">
        <div className="d-flex flex-column">
          {(!paymentMethod || paymentMethod === 'card' || isSmallDevice)
            && (retailer.stripe_id || hasMercadoPago || hasPayphone) && (
              <button
                type="button"
                className={`btn btn-checkout-item payment-method pt-md-3 pb-md-3 me-3 ${paymentMethod === 'card' ? 'active' : ''}`}
                onClick={selectPaymentMethod}
              >
                <div className="selected-delivery" />
                <img src={MiniCreditCard} alt="" className="me-4 me-md-3" />
                Tarjeta de crédito o débito
              </button>
            )}
          {/* {(!paymentMethod || paymentMethod === 'cash' || isSmallDevice) && ( */}
          {/*  <button */}
          {/*    type="button" */}
          {/*    className={`btn btn-checkout-item payment-method pt-md-3 pb-md-3 me-3 ${paymentMethod === 'cash' ? 'active' : ''}`} */}
          {/*    onClick={() => onChangeField('paymentMethod', 'cash')} */}
          {/*  > */}
          {/*    <div className="selected-delivery" /> */}
          {/*    <img src={MoneyIcon} alt="" /> */}
          {/*    Efectivo */}
          {/*  </button> */}
          {/* )} */}
          {(!paymentMethod
            || paymentMethod === 'whatsapp'
            || isSmallDevice) && (
              <button
                type="button"
                className={`btn btn-checkout-item payment-method pt-md-3 pb-md-3 me-3 ${paymentMethod === 'whatsapp' ? 'active' : ''}`}
                onClick={createWhatsappOrder}
              >
                <img src={WhatsappIcon} alt="" />
                Ordenar por WhatsApp
              </button>
            )}
        </div>

        {/* eslint-disable-next-line camelcase */}
        {!isSmallDevice && retailer.stripe_id && client_secret && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentFields ref={paymentRef} isFormValid={isFormValid} />
          </Elements>
        )}
        {!isSmallDevice
          && hasMercadoPago
          && Number(total) > 0
          && paymentMethod == 'card' && (
            <MercadoPagoCard
              ref={paymentMercadoPagoRef}
              total={total}
              retailer={retailer}
              isFormValid={isFormValid}
            />
          )}
        {!isEmpty(orderCreatedForPayphone) && (
          <PayphoneButton order={orderCreatedForPayphone} />
        )}
      </div>
    </>
  );

  const renderNextButton = () => (
    <div className="text-center mt-3">
      <button
        type="button"
        className="btn btn-main text-white ps-4 pe-4 ps-md-5 pe-md-5"
        onClick={() => setShowPaymentModal(false)}
      >
        Continuar
        <img src={ArrowIcon} alt="" className="ms-2" />
      </button>
    </div>
  );

  const openMobileMapModal = () => {
    setShowDeliveryModal(false);
    setShowMobileMapModal(true);
  };

  const closeMobileMapModal = (newAddress = null) => {
    if (!isEmpty(newAddress)) {
      onChangeField('address', newAddress.address);
      onChangeField('city', newAddress.city);
      onChangeField('state', newAddress.state);
      onChangeField('zipCode', newAddress.zip_code);
      onChangeField('country_id', newAddress.country_id);
      onChangeField('latitude', newAddress.latitude);
      onChangeField('longitude', newAddress.longitude);
    }

    setShowMobileMapModal(false);
    setShowDeliveryModal(true);
  };

  return (
    <div className="container mb-5 mb-md-0">
      <div className="mb-3 mt-3 d-flex d-md-none align-items-center position-relative">
        <button
          type="button"
          className="btn position-absolute top-0 start-0"
          onClick={onClickBack}
        >
          <img src={BackIcon} alt="" />
        </button>
        <div className="p-2 w-100">
          <h5 className="fw-bold mb-0 text-center">
            {isFinalStep ? title : 'Pagar'}
          </h5>
        </div>
      </div>

      <h2 className="mt-4 mb-4 fw-bold d-none d-md-block">{title}</h2>
      <div className="d-none d-md-flex align-items-center mb-4">
        <a href="/">
          <img
            className="checkout-header-avatar rounded-circle"
            src={retailer.logo_url}
            alt=""
          />
        </a>
        <div className="checkout-header">
          <Link className="text-decoration-none" to="/" ref={catalogoPageRef}>
            <h5 className="d-inline">{retailer.name || ''}</h5>
            &nbsp;&nbsp;
          </Link>
          {order.has_payment_transaction && (
            <div className="status--success">Pagado</div>
          )}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              {retailer.active && (
                <li className="breadcrumb-item">
                  <a href="/" className="text-decoration-none text-muted">
                    Catálogo
                  </a>
                </li>
              )}
              <li className="breadcrumb-item active" aria-current="page">
                Pagar
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {!isFinalStep && (
        <CheckoutSteps currentStep={currentStep} onChangeStep={() => { }} />
      )}
      <div className="row">
        <div className="col-md-8">
          <div className="checkout-container">
            <h3 className="fw-bold mb-4 d-none d-md-block">
              Información básica
            </h3>
            {(currentStep === 0 && isSmallDevice) || !isSmallDevice ? (
              <>
                <InformationFields
                  errors={errors}
                  isSmallDevice={isSmallDevice}
                  documentTypesOptions={documentTypesOptions}
                />
                {!isEmpty(orderCustomFields) && <OrderCustomFields />}
              </>
            ) : null}
            {(retailer.active_store_pickup || retailer.active_send_order) && (
              <>
                <h3 className="fw-bold mb-4 d-none d-md-block">
                  Entrega del pedido
                </h3>

                {((currentStep === 1 && isSmallDevice) || !isSmallDevice) && (
                  <div className="mb-md-4 mb-3">
                    <div className="d-flex flex-column flex-md-row">
                      {retailer.active_send_order && (
                        <button
                          type="button"
                          className={`btn btn-checkout-item flex-md-column me-3 ${deliveryMethod === 'send_order' ? 'active' : ''}`}
                          onClick={() => {
                            onChangeField('deliveryMethod', 'send_order');
                            setShowDeliveryModal(isSmallDevice);
                          }}
                        >
                          <div className="selected-delivery" />
                          <img
                            src={DeliveryIcon}
                            alt=""
                            className="me-4 me-md-0"
                          />
                          Enviar el pedido
                        </button>
                      )}
                      {retailer.active_store_pickup && (
                        <button
                          type="button"
                          className={`btn btn-checkout-item flex-md-column me-3 ${deliveryMethod === 'store_pickup' ? 'active' : ''}`}
                          onClick={() => {
                            onChangeField('deliveryMethod', 'store_pickup');
                            onChangeField('shipping_cost_id', '');
                            onChangeField('shipping_value', 0);
                          }}
                        >
                          <div className="selected-delivery" />
                          <img
                            src={StoreIcon}
                            alt=""
                            className="me-4 me-md-0"
                          />
                          Recoger en tienda
                        </button>
                      )}
                    </div>
                    <span className="ml-1 text-danger">
                      {errors.deliveryMethod}
                    </span>
                    {!isSmallDevice && (
                      <DeliveryFields
                        errors={errors}
                        isSmallDevice={isSmallDevice}
                        openMobileMapModal={openMobileMapModal}
                      />
                    )}
                  </div>
                )}
              </>
            )}

            {isFinalStep && (
              <ConfirmationFields
                title="Información básica"
                onClickEdit={() => setShowInformationModal(true)}
              >
                <>
                  <span>Nombres y Apellidos</span>
                  <p>
                    {name}
                    {' '}
                    {lastname}
                  </p>
                  <span>Correo electrónico</span>
                  <p>{email}</p>
                  <span>Teléfono</span>
                  <p>
                    {dialCode && dialCode.label}
                    {phone}
                  </p>
                </>
              </ConfirmationFields>
            )}

            {hasShippingMethod
              && (isFinalStep
                || (currentStep === 1 && deliveryMethod === 'send_order')) && (
                <ConfirmationFields
                  title={
                    deliveryMethod === 'send_order'
                      ? 'Detalles de envío'
                      : 'Recoger el pedido'
                  }
                  onClickEdit={() => {
                    if (!isFinalStep) {
                      setShowDeliveryModal(true);
                    } else {
                      setCurrentStep(1);
                    }
                  }}
                >
                  {deliveryMethod === 'send_order' && (
                    <div>
                      <div className="ml-1 text-danger" style={{ fontSize: 12 }}>
                        {errors.mobile_shipping}
                      </div>
                      <span>Dirección</span>
                      <p>
                        {address && `${address}, `}
                        {city && `${city}, `}
                        {state}
                      </p>
                      <div className="ml-1 text-danger" style={{ fontSize: 12 }}>
                        {errors.mobile_address}
                      </div>
                      <span>Código zip</span>
                      <p>{zipCode}</p>
                      <span className="ml-1 text-danger">{errors.zipCode}</span>
                    </div>
                  )}
                  {/* {deliveryMethod === 'store_pickup' && ( */}
                  {/*  <> */}
                  {/*    <span>Sucursal</span> */}
                  {/*    <p> */}
                  {/*      {store} */}
                  {/*    </p> */}
                  {/*  </> */}
                  {/* )} */}
                </ConfirmationFields>
              )}

            {currentStep === 2
              && isSmallDevice
              && !(orderParam && order.has_payment_transaction)
              && renderPaymentMethod()}

            {(isFinalStep || currentStep === 2)
              && paymentMethod === 'card'
              && isSmallDevice && (
                <ConfirmationFields
                  title="Método de pago"
                  onClickEdit={() => setShowPaymentModal(true)}
                >
                  <div className="d-flex">
                    <img src={TinyCardIcon} alt="" className="mt-3" />
                    <div className="ms-3">
                      <span>Tarjeta de crédito o débito</span>
                      <p>
                        ••••
                        {creditCardNumber.slice(-4)}
                      </p>
                    </div>
                  </div>
                </ConfirmationFields>
              )}

            {((isFinalStep || currentStep === 2) && paymentMethod === 'card' && isSmallDevice) && (
              <ConfirmationFields
                title="Método de pago"
                onClickEdit={() => setShowPaymentModal(true)}
              >
                <div className="d-flex">
                  <img src={TinyCardIcon} alt="" className="mt-3" />
                  <div className="ms-3">
                    <span>Tarjeta de crédito o débito</span>
                    <p>
                      ••••
                      {creditCardNumber.slice(-4)}
                    </p>
                  </div>
                </div>

              </ConfirmationFields>
            )}

            {(isFinalStep && paymentMethod !== 'card' && isSmallDevice) && (
              <>
                <div className="mb-4">
                  <h5 className="fw-bold mb-3">Método de pago</h5>
                  {paymentMethod === 'cash' && 'Efectivo'}
                  {paymentMethod === 'whatsapp' && 'Ordenar por Whatsapp'}
                </div>
              </>
            )}

            {(isFinalStep || !isSmallDevice) && (
              <>
                <span className={`fw-bold ${isSmallDevice ? 'h5' : 'h3'}`}>
                  Tu pedido
                </span>
                <div className="mt-3">
                  {isEmpty(filteredLines(orderLines)) ? (
                    <span className="ml-1 text-danger">
                      {errors.orderLines}
                    </span>
                  ) : (
                    renderCartLines()
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {(isFinalStep || !isSmallDevice) && (
          <div className="col-md-4">
            <div className="checkout-container ps-md-4 pe-md-4">
              <h5 className="fw-bold mb-3 pt-1 pt-md-0">Resumen</h5>
              <div className="d-flex justify-content-between mb-2">
                <span className="text-muted">Subtotal</span>
                <span>
                  {currency}
                  {formatMoney(subtotal)}
                  {' '}
                  {currencyCode}
                </span>
              </div>

              {/*
              <div className="d-flex justify-content-between mb-2">
                <span className="text-muted">Descuento</span>
                <span>
                  {totalDiscount > 0 && "-"}
                  {currency}
                  {formatMoney(totalDiscount)} {currencyCode}
                </span>
              </div>
              */}
              <div className="d-flex justify-content-between mb-2 mb-md-2">
                <span className="text-muted">Impuesto</span>
                <span>
                  {currency}
                  {formatMoney(totalTaxes)}
                  {' '}
                  {currencyCode}
                </span>
              </div>

              {Number(shipping_value) > 0 && (
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Envío</span>
                  <span>
                    {currency}
                    {formatMoney(shipping_value)}
                    {' '}
                    {currencyCode}
                  </span>
                </div>
              )}
              <div className="d-flex flex-md-column justify-content-between">
                <span className={!isSmallDevice ? 'h5' : 'fw-bold'}>Total</span>
                <span className={!isSmallDevice ? 'h3 fw-bold mb-0' : ''}>
                  {currency}
                  {formatMoney(total)}
                  {' '}
                  {currencyCode}
                </span>
              </div>
              {!(orderParam && order.has_payment_transaction) && (
                <>
                  <hr className="mt-4" />
                  {!isSmallDevice && renderPaymentMethod()}
                  {/* {(paymentMethod !== 'whatsapp' && paymentMethod) && ( */}
                  {/*  <button */}
                  {/*    className="btn btn-order-by-whatsapp text-white d-flex justify-content-center align-items-center w-100 mt-3 d-none d-md-block" */}
                  {/*    onClick={createOrder} */}
                  {/*    disabled={submitted} */}
                  {/*  > */}
                  {/*    Ordenar */}
                  {/*  </button> */}
                  {/* )} */}
                  <hr className="d-none d-md-block" />
                  {paymentMethod && !isSmallDevice && (
                    <button
                      className="btn text-main border-0 mb-3 w-100"
                      onClick={() => onChangeField('paymentMethod', null)}
                    >
                      Cambiar método de pago
                    </button>
                  )}
                  <button className="btn btn-info-pay d-flex flex-md-column align-items-center justify-content-center text-white">
                    <img
                      src={LockIcon}
                      alt=""
                      className="mb-md-3 me-2 me-md-0"
                    />
                    Pagos 100% seguros
                  </button>

                  {isSmallDevice && orderErrorMessage && (
                    <div className="col-md-12 text-center text-danger mt-10">
                      {orderErrorMessage}
                    </div>
                  )}
                </>
              )}
            </div>
            {!!orderParam && order.has_payment_transaction && (
              <div className="mt-4 d-flex flex-md-column align-items-center justify-content-center text-white py-4 bg-success rounded">
                Pagado
              </div>
            )}
          </div>
        )}
      </div>
      {(isFinalStep || !isSmallDevice) && (
        <div className="m-md-5 p-md-0 m-2 mb-5 pb-5 pt-4 text-center">
          <img
            src={PoweredIcon}
            alt=""
            className={isSmallDevice ? 'w-px-250' : ''}
          />
        </div>
      )}
      {isSmallDevice && (
        <StepFooter
          disabled={submitted}
          onClickNext={onClickNextStep}
          isFinalStep={isFinalStep || isWhatsappFinalStep}
          hideStepButton={!isEmpty(orderCreatedForPayphone)}
        />
      )}
      <BottomSheetModal
        visible={showDeliveryModal}
        onDismiss={() => {
          setShowDeliveryModal(false);
        }}
        name="delivery"
        title={
          deliveryMethod === 'send_order'
            ? '¿A dónde enviamos tu pedido?'
            : 'Sucursal'
        }
      >
        <DeliveryFields
          errors={errors}
          isSmallDevice={isSmallDevice}
          openMobileMapModal={openMobileMapModal}
        />
      </BottomSheetModal>
      <BottomSheetModal
        visible={showMobileMapModal}
        onDismiss={() => {
          setShowMobileMapModal(false);
          setShowDeliveryModal(true);
        }}
        name="address-map"
        title="Selecciona tu dirección"
      >
        {showMobileMapModal && (
          <MapComponent
            filterColor=""
            toggleModal={closeMobileMapModal}
            isSmallDevice={isSmallDevice}
          />
        )}
      </BottomSheetModal>
      <BottomSheetModal
        visible={showPaymentModal}
        onDismiss={() => setShowPaymentModal(false)}
        name="payment"
        title="Datos de tarjeta"
      >
        {/* eslint-disable-next-line camelcase */}
        {retailer.stripe_id && client_secret && (
          <>
            <div>
              <Elements stripe={stripePromise} options={options}>
                <PaymentFields ref={paymentRef} isFormValid={isFormValid} />
              </Elements>
            </div>
            {renderNextButton()}
          </>
        )}
        {isSmallDevice
          && hasMercadoPago
          && Number(total) > 0
          && paymentMethod == 'card' && (
            <>
              <MercadoPagoCard
                ref={paymentMercadoPagoRef}
                total={total}
                retailer={retailer}
                isFormValid={isFormValid}
              />
              {renderNextButton()}
            </>
          )}
        {!isEmpty(orderCreatedForPayphone) && (
          <PayphoneButton order={orderCreatedForPayphone} />
        )}
      </BottomSheetModal>
      <BottomSheetModal
        visible={showInformationModal}
        onDismiss={() => setShowInformationModal(false)}
        name="information"
        title="Información básica"
      >
        <InformationFields
          errors={errors}
          isSmallDevice={isSmallDevice}
          documentTypesOptions={documentTypesOptions}
        />
      </BottomSheetModal>
    </div>
  );
};

export default Checkout;
