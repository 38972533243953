import {
    SET_SHIPPING_COSTS
  } from '../actions/types';
  
  const initialState = {
    shippingCosts: null,
  };
  
  const shippingCostReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
      case SET_SHIPPING_COSTS: {
        return {
          ...state,
          shippingCosts: payload
        };
      }
      default:
        return state;
    }
  };
  
  export default shippingCostReducer;
  