import {
  createOrder as createOrderService,
  updateOrder as updateOrderService,
  getOrder as getOrderService
} from '../services';
import {
  GET_STRIPE_PAYMENT_SECRET,
  EMPTY_CART_AND_REMOVED_LINES,
  GET_ORDER_FOR_CHECKOUT
} from './types';

export const getStripePaymentSecret = (total) => (dispatch) => fetch(`/orders/stripe_payment_secret?total=${total}`)
  .then((res) => res.json())
  .then((data) => {
    dispatch({ type: GET_STRIPE_PAYMENT_SECRET, payload: data.client_secret });
  });

export const createPaidOrder = (params = {}, token) => (dispatch) => createOrderService(params, token)
  .then((res) => res.json())
  .then(() => {
    dispatch({ type: EMPTY_CART_AND_REMOVED_LINES });
  });

export const updatePaidOrder = (params = {}, token) => (dispatch) => updateOrderService(params, token)
  .then((res) => res.json())
  .then(() => {
    dispatch({ type: EMPTY_CART_AND_REMOVED_LINES });
  });

export const getOrderForCheckout = (order) => (dispatch) => {
  if (order) {
    getOrderService(order)
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: GET_ORDER_FOR_CHECKOUT, payload: data });
      });
  } else {
    dispatch({ type: GET_ORDER_FOR_CHECKOUT, payload: {} });
  }
};