import React from 'react';

const ConfirmationFields = ({ title, onClickEdit, ...props }) => (
  <div className="confirmation-fields pb-4">
    <div className="d-flex justify-content-between align-items-center">
      <h5 className="mb-0 fw-bold">{title}</h5>
      <button type="button" className="btn text-main pt-0 pb-0" onClick={onClickEdit}>
        Editar
      </button>
    </div>
    {props.children}
  </div>
);

export default ConfirmationFields;
