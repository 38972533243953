import React, { useEffect, useRef, useState } from "react";
import { Modal } from 'bootstrap';

const AllAddressesModal = ({toggleModal, addresses}) => {
  const [modal, setModal] = useState([]);
  const parseExceptionModal = useRef();

  useEffect(() => {
    const modal = new Modal(parseExceptionModal.current, { keyboard: false, backdrop: 'static' })
    setModal(modal)
    modal.show();
  }, []);

  const formatAddress = (location) => {
    let address = location.address;
    if (location.city) address += `, ${location.city}`;
    if (location.country) address += `, ${location.country}`;
    return address;
  }

  return (
    <div className="row col-md-12">
      <div className="modal" tabIndex="-1" ref={parseExceptionModal}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title fw-bold ps-md-2">Ubicaciones</h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}></button>
            </div>
            <div className="modal-body">
              <div class="col-md-12">
                { addresses.map((address => (
                  <div class="ps-md-2 pe-md-2">
                    <h6 class="fw-bold pt-1 pt-md-0">{address.name}</h6>
                    <div class="d-flex justify-content-between mb-2">
                      <span class="text-muted">{formatAddress(address)}</span>
                    </div>
                  </div>
                )))
                }
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={() => {modal.hide();toggleModal();}} type="button" className="btn btn-main text-white">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllAddressesModal;
