import React, { useEffect } from 'react';
import { Offcanvas } from 'bootstrap';

let offcanvas;

const BottomSheetModal = ({
  className = '',
  visible,
  title,
  name,
  onDismiss,
  ...props
}) => {
  useEffect(() => {
    const el = document.getElementById(`bottomSheetModal-${name}`);
    offcanvas = Offcanvas.getOrCreateInstance(el);
    if (visible) {
      offcanvas.show();
    } else {
      offcanvas.hide();
    }
  }, [visible]);

  useEffect(() => {
    const el = document.getElementById(`bottomSheetModal-${name}`);
    if (el) {
      el.addEventListener('hide.bs.offcanvas', onDismiss);
    }
    return () => {
      offcanvas.hide();

      if (el) {
        el.removeEventListener('hide.bs.offcanvas', onDismiss);
      }
    };
  }, []);
  return (
    <div
      className={`offcanvas offcanvas-bottom bottom-sheet-modal ${className}`}
      tabIndex="-1"
      id={`bottomSheetModal-${name}`}
      aria-labelledby="bottomSheetModalLabel"
    >
      {title && (
        <div className="offcanvas-header pb-0">
          <h5 className="offcanvas-title mb-0 fw-bold" id="bottomSheetModalLabel">{title}</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={onDismiss} />
        </div>
      )}
      <div className="offcanvas-body pt-0">
        {props.children}
      </div>
    </div>
  );
};

export default BottomSheetModal;
