import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, find, forEach, isEmpty } from 'lodash';
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import { changeOrderCustomField } from '../../../actions';
import es from "date-fns/locale/es";


registerLocale("es", es);

const OrderCustomFields = () => {
  const {
    orderCustomFields = []
  } = useSelector((reduxState) => reduxState.cartReducer);
  const [customFieldsGrouped, setCustomFieldsGrouped] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let fullArray = [];
    let newArray = [];
    if (!isEmpty(orderCustomFields)) {
      forEach(orderCustomFields, (item, index) => {
        newArray.push(item);
        if (newArray.length == 2) {
          fullArray.push(newArray);
          newArray = [];
        }
        else {
          if ((index + 1) == orderCustomFields.length && newArray.length > 0) {
            fullArray.push(newArray);
          }
        }
      });

      setCustomFieldsGrouped(fullArray);
    }
  }, [orderCustomFields]);

  const setValues = (fieldName, value) => {
    const clonedCustomFieldsGrouped = cloneDeep(customFieldsGrouped);

    let fieldIn;
    forEach(clonedCustomFieldsGrouped, fields => {
      fieldIn = find(fields, field => { return field.name == fieldName; });
      if (fieldIn) {
        fieldIn.answer = value;
        fieldIn.error = '';
        if (fieldIn.mandatory_in_shops && !fieldIn.answer) {
          fieldIn.error = '* Requerido'
        };
      }
    });

    dispatch(changeOrderCustomField(fieldName, value));
    setCustomFieldsGrouped(clonedCustomFieldsGrouped);
  }

  const handleInputChange = (e, field) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (field.kind == 'integer') {
      value = value.replace(/\D/g, '');
    }

    if (field.kind == 'float') {
      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
      value = value.match(regex)[0];
    }

    setValues(name, value);
  };

  const onChangeDateCustomField = (date, field) => {
    setValues(field.name, date.toISOString())
  }

  const handleCustomFieldSelect = (option, field) => {
    setValues(field.name, option.value);
  }

  const printError = (field) => {
    return(<span className="ml-1 text-danger">{field.error}</span>)
  }

  const printCustomField = (field) => {
    switch (field.kind) {
      case 'string':
      case 'integer':
      case 'float':
        return(
          <>
            <div className="checkout-input-container custom-field-container pt-1 pb-2">
              <span className="checkout-input-label">
                {field.label}
              </span>
              <input
                onChange={(e) => handleInputChange(e, field)}
                type="text"
                value={field.answer}
                name={field.name}
              />
            </div>
            {printError(field)}
          </>
        )
      case 'date':
        return(
          <>
            <div className="checkout-input-container pt-1 pb-2">
              <span className="checkout-input-label">
                {field.label}
              </span>
              <DatePicker
                selected={field.answer ? Date.parse(field.answer) : ""}
                onChange={(date) => onChangeDateCustomField(date, field)}
                locale="es"
                yearDropdownItemNumber={5}
                dateFormat="yyyy-MM-dd"
                showYearDropdown
                name={field.name}
              />
            </div>
            {printError(field)}
          </>
        )
      case 'boolean': {
        return(
          <>
            <span>{field.label}&nbsp;&nbsp;</span>
            <label className="switch mb-2 slider-box">
              <input
                type="checkbox"
                checked={field.answer}
                onChange={(e) => handleInputChange(e, field)}
                name={field.name}
              />
              <span className="slider round" />
            </label>
          </>
        )
      }
      case 'list':
        return(
          <>
            <div className="checkout-input-container pt-1 pb-2">
              <span className="checkout-input-label">
                {field.label}
              </span>
              <Select
                placeholder="Selecciona"
                className="checkout-selector"
                classNamePrefix="checkout-selector"
                isSearchable
                options={field.list_options}
                value={[field.list_options.find((el) => el.value == field.answer)]}
                onChange={(option) => handleCustomFieldSelect(option, field)}
              />
            </div>
            {printError(field)}
          </>
        )
      case 'default':
        return null;
    }
  }

  const renderCustomFields = (fields, pIndex) => {
    return(
      <div className="row">
        {fields.map((field, index) => (
          <div key={`order-cunstom-field-${pIndex}-${index}`} className={`col-md-6 pe-md-2 mb-3 ${field.kind == 'boolean' ? 'd-flex align-items-center' : ''}`}>
            {printCustomField(field, index)}
          </div>
        ))}
      </div>
    )
  };

  return (
    <>
      <h3 className="fw-bold mb-4 d-md-block">Datos adicionales</h3>
      <div className="mb-4 mt-3 mt-md-0">
        {customFieldsGrouped.map((fields, index) => (
          <div key={`cunstom-field-container-${index}`}>
            {renderCustomFields(fields, index)}
          </div>
        ))}
      </div>
    </>
  );
};

export default OrderCustomFields;
