import React from 'react';

const SocialLabel = ({ icon, name, url = '', big }) => {
  const socialURL = url.startsWith('https://') || url.startsWith('http://') ? url : `https://${url}`;
  return (
    <div className="mb-sm-1 mb-lg-2">
      <a href={socialURL} target="_blank" rel="noreferrer noopener" className="d-flex flex-row align-items-center social-network-link">
        <img
          className={`float-start catalog-header-icon ${big ? 'big-icon' : 'me-2'}`}
          src={icon}
          alt=""
        />
        <span className="d-none d-md-block text-white">{name}</span>
      </a>
    </div>
  );
};

export default SocialLabel;
