import { forEach } from 'lodash';

export const filteredLines = (lines = []) => {
  return lines.filter((el) => el._destroy !== true);
}

export const formatOrderDataToSave = (data) => {
  const {
    orderLines,
    name,
    lastname,
    email,
    dialCode,
    phone,
    documentType,
    documentId,
    address,
    description,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    country_id,
    deliveryMethod,
    orderCustomFields = [],
    orderLinesRemoved,
    shipping_value,
    shipping_cost_id
  } = data;

  const formData = {
    delivery_method: deliveryMethod,
    address,
    description,
    city,
    state,
    zip_code: zipCode,
    latitude,
    longitude,
    country_id,
    shipping_value,
    shipping_cost_id,
    customer_attributes: {
      first_name: name,
      last_name: lastname,
      email,
      country_id: dialCode ? dialCode.value : null,
      phone: dialCode.dialCode + phone,
      id_type: documentType ? documentType.value : null,
      id_number: documentId,
      address,
      city,
      state,
      zip_code: zipCode
    },
    order_items_attributes: [],
    custom_answers_attributes: []
  };

  forEach(orderCustomFields, (item) => {
    formData.custom_answers_attributes.push({
      id: item.id ? item.id : null,
      custom_field_id: item.custom_field_id,
      answer: item.answer
    });
  });

  forEach(orderLines, (item) => {
    const productPrice = item.unit_price || item.product?.price || 0;

    let newOrderItems = {
      id: item.id ? item.id : null,
      product_id: item.product?.id || null,
      quantity: item.quantity,
      title: item.title,
      unit_price: productPrice,
      custom_tax: item.custom_tax || 0,
      order_item_variants_attributes: []
    }

    forEach(item.variants, variant => {
      newOrderItems.order_item_variants_attributes.push({
        id: variant.order_item_variant_id,
        product_variant_option_id: variant.variant_option_id
      });
    });

    formData.order_items_attributes.push(newOrderItems);
  });

  forEach(orderLinesRemoved, (item) => {
    formData.order_items_attributes.push({ ...item, _destroy: true });
  });

  return formData;
}

export const pluck = (arr, key) => arr.map(i => i[key]);

export const variantsHowText = (variants = []) => {
  let text = '';
  forEach(variants.filter((el) => el._destroy !== true), (variant, index) => {
    if (index > 0) text += ', ';

    text += `${variant.variant_name}: ${variant.variant_option_name}`;
  });

  if (text) return `(${text})`;

  return text;
}

export const geocoderLocationInfo = (results) => {
  const location = {
    address: results[0].formatted_address,
    city: '',
    state: '',
    country: '',
    country_id: '',
    zip_code: '',
    latitude: results[0].geometry.location.lat(),
    longitude: results[0].geometry.location.lng()
  };

  results[0].address_components.forEach(component => {
    const types = component.types;

    if (types.includes('locality')) {
      location.city = component.long_name;
    };

    if (types.includes('administrative_area_level_1')) {
      location.state = component.long_name;
    };

    if (types.includes('country')) {
      location.country = component.long_name;
      location.country_id = component.short_name;
    };

    if (types.includes('postal_code')) {
      location.zip_code = component.long_name;
    };
  });

  return location;
};