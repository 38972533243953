import React, { useState } from 'react';
import SearchIcon from 'images/icono-buscar-outline.svg';
import ShoppingCartIcon from 'images/Shopping-cart-outline.svg';
import ArrowBackIcon from 'images/Arrow-ios-back-fill.svg';
import MenuIcon from 'images/Menu-outline.svg';
import { useSelector } from 'react-redux';

const SearchBar = ({ isSmallDevice, handleSearchValueChange }) => {
  const [showSearchInput, setShowSearchInput] = useState(!isSmallDevice);

  const showCartButton = !showSearchInput || (showSearchInput && !isSmallDevice);
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);

  const { orderLines } = useSelector((reduxState) => reduxState.cartReducer);

  return (
    <div className="bg-white d-flex p-md-3 p-2 ps-4 pe-4 search-bar-container">
      {showSearchInput && isSmallDevice
      && (
      <button type="button" className="btn ps-0 pe-0" onClick={() => setShowSearchInput(false)}>
        <img src={ArrowBackIcon} alt="" />
      </button>
      )}
      <div className="input-group search-bar-content">
        {
          showSearchInput && (
            <>
              <img src={SearchIcon} alt="" className="search-bar-icon search-bar-bg" />
              <input
                id="search-bar"
                type="text"
                className="form-control border-0 search-bar-input search-bar-bg shadow-none"
                placeholder={`Buscar en ${retailer.name}`}
                aria-describedby="iconSearch"
                onKeyUp={handleSearchValueChange}
              />
            </>
          )
        }
        {
          !showSearchInput && (
            <div className="flex-grow-1 align-items-stretch">
              <button
                className="btn ps-0 pe-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#drawerCategory"
                aria-controls="drawerCategory"
              >
                <img src={MenuIcon} alt="" />
              </button>
              <span className="ps-lg-2">Categorías</span>
            </div>
          )
        }
        {
          showCartButton && (
            <>
              <button type="button" className="btn d-md-none" onClick={() => setShowSearchInput(true)}>
                <img src={SearchIcon} alt="" />
              </button>
              <button
                type="button"
                className="btn btn-main text-white search-bar-btn d-flex"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasCart"
              >
                <div className="position-relative">
                  {orderLines.length > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-white text-black">
                      {orderLines.length}
                    </span>
                  )}
                  <img src={ShoppingCartIcon} alt="" />
                </div>
                <span className="d-none d-md-block ms-2">
                  Ver carrito
                </span>
              </button>
            </>
          )
        }
      </div>
    </div>
  );
};

export default SearchBar;
