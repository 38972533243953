import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {cloneDeep, isEmpty} from 'lodash';
import ProductModal from '../CatalogProducts/ProductModal';
import SubtotalFooter from '../SubtotalFooter';
import { getProduct } from '../../../actions';
import CatalogProductItem from '../CatalogProductItem';

const ProductsList = ({
  isSmallDevice
}) => {
  const dispatch = useDispatch();
  const { products } = useSelector((reduxState) => reduxState.catalogReducer);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { product } = useSelector((reduxState) => reduxState.catalogReducer);

  useEffect(() => {
    if (!isEmpty(selectedProduct)) {
      dispatch(getProduct(selectedProduct.web_id));
    } else {
      dispatch(getProduct(''));
    }
  }, [selectedProduct]);

  const getProductsGrouped = () => {
    const array = cloneDeep(products);
    const result = [];
    const numberOfGroup = isSmallDevice ? 2 : 3;
    while (array.length > 0) {
      const groupByNumber = array.length >= numberOfGroup ? numberOfGroup : array.length;
      result.push(array.splice(0, groupByNumber));
    }
    return result;
  };

  return (
    <div>
      <div className={`category-product-carousel bg-white ${products.length == 0 ? "pb-3" : ""}`}>
        { products.length ? (
          <>
            {
              getProductsGrouped().map((groupedProducts, index) => (
                <div key={`product-grouped-${index}`}>
                  <div className="row w-100 d-flex pb-3">
                    {
                      groupedProducts.map((item) => (
                        <CatalogProductItem
                          key={item.web_id}
                          product={item}
                          onSelectProduct={setSelectedProduct}
                        />
                      ))
                    }
                  </div>
                  <br />
                </div>
              ))
            }
          </>) : (<span style={{padding: '10px 16px'}}>Sin resultados</span>)}
        <ProductModal
          product={product}
          onDismiss={() => setSelectedProduct({})}
          isSmallDevice={isSmallDevice}
        />
      </div>
    </div>
  );
};

export default ProductsList;
